import { CaretRight, House } from "@phosphor-icons/react";
import CustomText from "components/CustomText";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbSection({ header_info,name }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        gap: "0.4rem",
        alignItems: "center",
        color: header_info.secondary_text_color,
      }}
    >
      <House
        size={20}
        style={{
          marginRight: "0.8rem",
          cursor:'pointer'
        }}
        onClick={()=>{
          navigate('/')
        }}
      />
      <CaretRight size={16} />
      <CustomText
        text={name}
        color={header_info.primary_text_color}
      />
    </div>
  );
}
