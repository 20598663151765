import { Container } from "@mui/material";
import { login } from "components/actions/AppActions";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import CustomSection from "components/CustomSection";
import CustomText from "components/CustomText";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { post } from "utilities/requests";

export default function LoginPage() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let form = new FormData();
    form.append("email", email);
    form.append("password", password);

    const toast_id = toast.loading("Fazendo login...");

    const res = await post("auth/login", form);

    if (res?.status === true) {
      localStorage.setItem("token", res.access_token);
      localStorage.setItem("user", JSON.stringify(res.user));
      
      dispatch(login({token:res.access_token,user:res.user}));
      toast.update(toast_id, {
        render: "Login feito com sucesso!",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
      if(searchParams.get('go')=='cart'){
        navigate("/carrinho");
      }
      else{
        navigate("/dashboard");
      }
    } else {
      let error = undefined;

      if (res?.errors?.password) {
        error = res.errors.password[0];
      }
      if (res?.errors?.email) {
        error = res.errors.email[0];
      }
      try {
        error = res.errors[0];
      } catch {}

      toast.update(toast_id, {
        render: error || "Erro ao fazer login. Consulte o Suporte.",
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    }
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          maxWidth: "22rem",
          width: "100%",
          gap: "1rem",
        }}
      >
        <CustomText
          text={"Faça seu login"}
          variant={"h3"}
          size={"1.94rem"}
          justify={"center"}
          bold
          style={{
            textTransform: "uppercase",
            marginBottom: "1rem",
          }}
        />
        <CustomInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <CustomInput
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <a
          href="esqueci-a-senha"
          style={{
            textAlign: "right",
            fontSize: "0.9rem",
            color: "#525252",
          }}
        >
          Esqueci minha senha
        </a>
        <CustomButton text="Entrar" />
      </form>
    </CustomSection>
  );
}
