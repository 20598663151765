import { CircularProgress, IconButton } from "@mui/material";
import style from "./style.module.css";
import { Eye, PencilLine, Trash } from "@phosphor-icons/react";
import AdminProductItem from "./ProductItem";
import { useEffect, useState } from "react";
import windowReloadPage from "utilities/window_reload";
import { get } from "utilities/requests";
import CustomInput from "components/CustomInput";
import { useNavigate } from "react-router-dom";

export default function AdminProdutosTable() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const [search_debounce, setSearchDebounce] = useState("");

  const [products, setProducts] = useState([]);

  const getProduct = async () => {
    setLoading(true);

    const res = await get(`products/list?search=${search}`);
    setProducts(res.products);
    setLoading(false);
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    clearTimeout(search_debounce);

    setSearchDebounce(
      setTimeout(async () => {
        await getProduct();
      }, 500)
    );
  }, [search]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        <CustomInput
          placeholder="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            width: "100%",
            maxWidth: "30rem",
          }}
        />

        <label
          htmlFor="upload_tag_button"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
            width: "fit-content",
          }}
          onClick={() => navigate("adicionar")}
        >
          Adicionar produto
        </label>
      </div>
      <table className={style.customTable}>
        <thead>
          <tr>
            <th>Imagem</th>
            <th>Nome</th>
            <th>Preço</th>
            <th>Quantidade</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {loading==false && products.map((product, index) => (
            <AdminProductItem
              key={index}
              {...{
                product,
                refetch: getProduct,
              }}
            />
          ))}
        </tbody>
       
      </table>
      {loading==false && products.length==0 && <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:15}}>
          <p style={{textAlign:'center'}}>Nenhum produto encontrado</p>
        </div>}
        {loading==true && products.length==0 && <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:15}}>
          <CircularProgress/>
        </div>}
    </>
  );
}
