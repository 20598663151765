import { API_URL } from "./variables";
const publicKey = 'pk_3PVpeg4CJquK4Djo'

export const get = async (path) => {
    const token = localStorage.getItem("token");
    const headers = {
        "Accept": "application/json",
        ...(token && {"Authorization": "Bearer " + token})
    };
    let httpCode;

    let response = await fetch(API_URL + path, {
        method: "GET",
        headers: headers
    })
    .then(async (res) => {
        httpCode = res.status;
        return await res.json();
    })
    .catch(err => console.log("Error to get: " + err.message));
    response["httpCode"] = httpCode;

    return response;
}

export const post = async (path, body) => {
    const token = localStorage.getItem("token");
    const headers = {
        "Accept": "application/json",
        ...(!(body instanceof FormData) && {"Content-type": "application/json"}),
        ...(token && {"Authorization": "Bearer " + token})
    };

    let httpCode;
    let response = await fetch(API_URL + path, {
        method: "POST",
        headers: headers,
        body: body
    })
    .then(async (res) => {
        httpCode = res.status;
        return await res.json();
    })
    .catch(err => console.log("Error to post: " + err.message));

    response["httpCode"] = httpCode;
    return response;
}

export const put = async (path, body) => {
    const headers = {
        "Accept": "application/json",
        ...(!(body instanceof FormData) && {"Content-type": "application/json"})
    }
    let httpCode;

    let response = await fetch(path, {
        method: "PUT",
        headers: headers,
        body: body
    })
    .then(async (res) => {
        httpCode = res.status;
        return await res.json();
    })
    .catch(err => console.log("Error to put: " + err.message));
    response["httpCode"] = httpCode;

    return response;
}

export const patch = async (path, body) => {
    const headers = {
        "Accept": "application/json",
        ...(!(body instanceof FormData) && {"Content-type": "application/json"})
    }
    let httpCode;
    
    let response = await fetch(path, {
        method: "PATCH",
        headers: headers,
        body: body
    })
    .then(async (res) => {
        httpCode = res.status;
        return await res.json();
    })
    .catch(err => console.log("Error to patch: " + err.message));
    response["httpCode"] = httpCode;

    return response;
}

export const useDelete = async (path, body) => {
    const headers = {
        "Accept": "application/json",
        "Content-type": "application/json"
    }
    let httpCode;

    let response = await fetch(path, {
        method: "DELETE",
        headers: headers,
        body: body
    })
    .then(async (res) => {
        httpCode = res.status;
        return await res.json();
    })
    .catch(err => console.log("Error to delete: " + err.message));
    response["httpCode"] = httpCode;

    return response;
}

export const CreateToken = async (card) => {
    let httpCode;

    let response = await fetch(`https://api.pagar.me/core/v5/tokens?appId=${publicKey}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            type: 'card',
            card
        })
    })
        .then(res => {
            httpCode = res.status;
            return res.json();
        })
        .then(json => (json))
        .catch(err => console.log("Error to get: " + err.message));

    response["httpCode"] = httpCode;

    return response;
}