import CustomText from "components/CustomText";
import UsersTable from "./UsersTable";

export default function AdminProdutosPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "3rem 2rem",
      }}
    >
      <CustomText text="Usuários" size="1.4rem" bold />
      <CustomText
        text="Lista de usuários."
        size="1rem"
        color="#4d4c4c"
      />

      <UsersTable />
    </div>
  );
}
