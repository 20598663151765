import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import { Box, CircularProgress, Container, Select } from "@mui/material";

import Stepper from 'react-stepper-horizontal';
import { discountCalculator, maskCep, maskCpfCnpj, maskNumber, maskValidadeCartao, moneyMask, undoMoneyMask } from 'utilities/helpers';
import Breadcrumb from 'components/Header/breadcrumb';
import PageHeader from 'components/Header/page-header';
import { mudarDados } from 'components/actions/AppActions';
import BreadcrumbSection from 'pages/VerProduto/sections/Breadcrumb';
import style from "./style.module.css";
import { STORAGE_URL } from 'utilities/variables';
import { AddressBook, ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Bag, Cards, Truck, XCircle } from '@phosphor-icons/react';
import './style.css';
import CustomText from 'components/CustomText';
import { CreateToken, get, post } from 'utilities/requests';
import { toast } from 'react-toastify';
import Navigate from 'components/Navigate/navigate';
import moment from 'moment';
import amex from 'assets/images/cartoes/amex.png';
import elo from 'assets/images/cartoes/elo.png';
import hypercard from 'assets/images/cartoes/hypercard.png';
import mastercard from 'assets/images/cartoes/mastercard.png';
import visa from 'assets/images/cartoes/visa.png';
import jcb from 'assets/images/cartoes/jcb.png';
import aura from 'assets/images/cartoes/aura.png';
import diners_club from 'assets/images/cartoes/diners_club.png';
import discover from 'assets/images/cartoes/discover.png';
import Modal from 'react-modal';
import { Close } from '@mui/icons-material';
const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)',
        // height: 270,
        // top: '25%',
        right: 40,
        left: 40
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',

        // overflowY: 'scroll'
    }
};
class Card {
    constructor() {
        var self = this; this.mask = { DEFAULT_CC: '0000 0000 0000 0000', DEFAULT_CVC: '000', }
        this.type = {
            VISA: { name: 'visa', detector: /^4/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 },
            MASTERCARD: { name: 'mastercard', detector: /^(5[1-5]|2(2(2[1-9]|[3-9])|[3-6]|7([0-1]|20)))/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 },
            AMEX: { name: 'amex', detector: /^3[47]/, cardLength: 15, cvcLength: 4, maskCC: '0000 000000 00000', maskCVC: '0000', order: 99 },
            DISCOVER: { name: 'discover', detector: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 2 },
            HIPERCARD: { name: 'hipercard', detector: /^(?:606282|384100|384140|384160)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 4 }, DINERS: { name: 'diners', detector: /^(300|301|302|303|304|305|36|38)/, cardLength: 14, cvcLength: 3, maskCC: '0000 000000 0000', maskCVC: self.mask.DEFAULT_CVC, order: 5 },
            JCB_15: { name: 'jcb_15', detector: /^(?:2131|1800)/, cardLength: 15, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 6 },
            JCB_16: { name: 'jcb_16', detector: /^(35)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 7 },
            ELO: { name: 'elo', detector: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67([0-6][0-9]|7[0-8])|9\d{3})|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|(1|2|3)[0-9]|8[5-9]|9[0-9])|5((3|9)[0-8]|4[1-9]|([0-2]|[5-8])\d)|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|6516(5[2-9]|[6-7]\d)|6550(2[1-9]|5[0-8]|(0|1|3|4)\d))\d*/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 1 },
            AURA: { name: 'aura', detector: /^((?!5066|5067|50900|504175|506699)50)/, cardLength: 19, cvcLength: 3, maskCC: '0000000000000000000', maskCVC: self.mask.DEFAULT_CVC, order: 3 }
        };
    }
}

function detect_card(text = '') {
    text = text.replace(/ /g, '');
    let card = new Card();
    let types = Object.values(card.type);
    let brand = '';
    let index = null;
    let masked_number = '';
    let card_length = 0;
    for (let i = 0; i < types.length; i++) {
        if (text.match(types[i].detector)) {
            brand = types[i].name;
            card_length = types[i].cardLength;
            index = i;
            break;
        }
    }
    if (index != null) {
        let index_mask = 0;

        for (let i = 0; i < text.length && i < types[index].cardLength; i++) {
            if (!isNaN(text[i])) {
                if (types[index].maskCC[index_mask] == ' ') {
                    masked_number += ' ';
                    index_mask++;
                }
                if (types[index].maskCC[index_mask] == '0') {
                    masked_number += text[i];
                }
                index_mask++;
            }

        }
    }
    else {
        masked_number = text;
    }
    return { brand, masked_number, index, card_length };
}

var regras_parcelas = [
    {
        min: 0.00,
        max: 39.99,
        parcela: 1
    },
    {
        min: 40.00,
        max: 59.99,
        parcela: 2
    },
    {
        min: 60.00,
        max: 79.99,
        parcela: 3
    },
    {
        min: 80.00,
        max: 99.99,
        parcela: 4
    },
    {
        min: 100.00,
        max: 299.99,
        parcela: 5
    },
    {
        min: 300.00,
        parcela: 6
    }
]


class CartResume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // shipping: { delivery_method_id: '', final_shipping_cost: 0 },
            update: true,
            loading_frete: false,
            msgErro: '',
            loading_add: {},

            numero_cartao: '',
            cvv: '',
            validade: '',
            cpf_cnpj: '',
            nome: '',
            types: { brand: '', masked_number: '', card_length: '' },
            pagamento_hidden: true,
            installments: [],
            zip: '',
            address: '',
            number: '',
            neighborhood: '',
            city: '',
            state: '',
            latitude: -1,
            longitude: -1,
            complement: '',

            loading_save: false,
            address_select: {},
            endereco_hidden: true,
            precos_prazos: [],
            loading: true,
            modal_open: false,
            loading_order:false

        }
    }

     async store_order() {
            this.setState({ loading_order: true })
        console.log(this.props.shipping)
            let products={}

            this.props.cartlist.map((item)=>{
                products[item.id]=item;
            })

    
            const res = await post("orders/store", JSON.stringify({
                address_id: this.props.address_select.id,
                address_charge_id: this.props.address_charge.id,
                installments: this.props.installment,
                card_id:this.props.card.id,
                shipping:this.props.shipping,
                volumes:this.props.shipping.volumes_package,
                total:this.calculateTotal(),
                method:this.props.card.type,
                products
    
            }));
            if (res.httpCode === 200) {
                console.log(res.carts)
                this.setState({
                    loading_order: false, 
                })
            this.setState({ navigate: '/dashboard' })
                
    
    
            } else {
                if (res.errors != null || res.error != null) {
                    let errors = Object.values(res.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
    
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                    toast.error(erro);
    
                }
                this.setState({ loading_order: false })
            }
            // setLoadingAdd(false);
    
    
        }

    async componentDidMount() {

        if (this.props.token == null || this.props.user?.id == undefined || this.props.cartlist.length == 0) {
            this.setState({ navigate: '/carrinho' })
            return;
        }
        let address = this.props.address_select;
        let shipping = this.props.shipping;

        let data = {};
        if (this.props.address_select?.id == undefined) {
            address = JSON.parse(localStorage.getItem('address_select') || '{}');
            if (address?.id != undefined && this.props.user.addresses.filter((item) => item.id == address.id).length > 0) {
                data.address_select = address
                data.address_charge = address
            }
            else {
                this.setState({ navigate: '/carrinho' })
                return;
            }

        }
        if (this.props.shipping?.delivery_method_id == undefined) {
            shipping = JSON.parse(localStorage.getItem('shipping') || '{}');
            if (shipping?.delivery_method_id != undefined) {
                // await this.get_shipping(address.id, shipping.delivery_method_id);
            }
            else {
                this.setState({ navigate: '/carrinho' })
                return;
            }

        }

        await this.get_shipping(address.id, shipping.delivery_method_id);


        if (this.props.card?.id == undefined) {
            let card = JSON.parse(localStorage.getItem('card') || '{}');
            let installment = localStorage.getItem('installment');

            if (card?.id != undefined && !Number.isNaN(installment)) {
                let card_temp = this.props.user.cards.filter((item) => item.id == card.id)
                if (card_temp.length == 0) {
                    this.setState({ navigate: '/carrinho' })
                    return;
                }
                else {
                    data.card = { ...card_temp[0], type: 'credit_card' };
                    data.installment = installment;

                }
            }
            else {
                this.setState({ navigate: '/carrinho' })
                return;
            }

        }
        this.props.mudarDados({ ...data })
        console.log('card', data)
        this.get(this.props.address_select?.id)
        // quantityInputs();

    }



    async store() {
        this.setState({ loading_save: true })
        const { id, httpCode } = await CreateToken({
            cvv: this.state.cvv, exp_month: this.state.validade.split('/')[0], exp_year: this.state.validade.split('/')[1], holder_name: this.state.nome, holder_document: this.state.cpf_cnpj.replace(/\D/g, ''), number: this.state.numero_cartao.replace(/ /g, ''), brand: this.state.types.brand.toLowerCase()
        })
        const res = await post("cards/create", JSON.stringify({ cvv: this.state.cvv, token: id, billing_address: this.state.address_select }));
        if (res.httpCode === 200) {
            console.log(res.carts)
            this.props.mudarDados({ user: res.user, card: res.user.cards[0] })
            this.setState({
                loading_save: false, numero_cartao: '',
                cvv: '',
                validade: '',
                cpf_cnpj: '',
                nome: '',
                types: { brand: '', masked_number: '', card_length: '' },
                pagamento_hidden: true,
            })

            toast.success(res.message);

        } else {
            if (res.errors != null || res.error != null) {
                let errors = Object.values(res.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);

                //   setTimeout(()=>{
                //     this.get_processo();
                //   });
                toast.error(erro);

            }
            this.setState({ loading_save: false })
        }
        // setLoadingAdd(false);


    }

    async get_shipping(id, delivery_method_id) {
        const res = await post("cart/get_shipment_prices", JSON.stringify({
            produtos: this.props.cartlist.map((item) => {
                return {
                    id: item.product.id,
                    largura: item.product.largura,
                    altura: item.product.altura,
                    comprimento: item.product.comprimento,
                    peso: item.product.peso,
                    sale_price: item.product.header_info.discount ?

                        discountCalculator(
                            item.product.header_info.price,
                            item.product.header_info.discount,
                            item.product.header_info.is_percentage_discount
                        ) :

                        null,
                    price: undoMoneyMask(item.product.header_info.price),
                    qty: item.quantity
                }
            }),
            address_id: id,
        }));
        if (res.httpCode === 200 && res.errors == undefined) {
            let precos_prazos = [];
            for (let i = 0; i < res.precos_prazos.length; i++) {
                precos_prazos.push({
                    final_shipping_cost: res.precos_prazos[i].vltotal,
                    delivery_estimate_business_days: res.precos_prazos[i].prazo,
                    retirada: res.precos_prazos[i].retirada,
                    entrega_local: res.precos_prazos[i].entrega_local,
                    volumes: res.precos_prazos[i].packages ? res.precos_prazos[i].packages : null,
                    agency_id: res.precos_prazos[i].agency_id ? res.precos_prazos[i].agency_id : null,
                    delivery_method_id: res.precos_prazos[i].id ? res.precos_prazos[i].id : Math.random(),
                    description: res.precos_prazos[i].entrega_local == true ? 'Entrega local' : (res.precos_prazos[i].retirada == true
                        ? 'Retirada no estabelecimento' : res.precos_prazos[i].description),
                        volumes_package:res.volumes
                    
                })
            }
            if (precos_prazos.filter((item) => item.delivery_method_id == delivery_method_id).length > 0) {
                this.props.mudarDados({ shipping: precos_prazos.filter((item) => item.delivery_method_id == delivery_method_id)[0] })
            }
            else {
                this.setState({ navigate: '/carrinho' })

            }

        }
        else {
            this.timeout_shipping = setTimeout(() => {
                this.get_shipping(id)
            }, 5000)
        }
    }

    async get(id) {
        const res = await get("cart/get?random_id=" + localStorage.getItem('random_id'));
        if (res.httpCode === 200 && res.errors == undefined) {

            console.log('aquiii')
            let installments = []
            let frete = (this.props.shipping.delivery_method_id == undefined ? 0 : Number(this.props.shipping.final_shipping_cost));
            console.log(frete)
            console.log(this.calculateTotal())
            installments.push({ value: 1, label: '1x de ' + moneyMask(parseFloat(this.calculateTotal()) + frete) })
            let installment = 1

            for (let j = 2; j <= 12; j++) {
                console.log(frete, parseFloat(this.calculateTotal()))
                installments.push({ value: j, label: j + 'x de ' + moneyMask(((parseFloat(this.calculateTotal()) + frete) / j).toFixed(2)) });
            }
            console.log(installments)
            this.props.mudarDados({ user: res.user, carts: res.carts })
            this.setState({ loading: false, installments })
        }
        else if (res.errors != undefined) {
            let errors = Object.values(res.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                else
                    erro += errors[i];
            }
            console.log(erro);

            //   setTimeout(()=>{
            //     this.get_processo();
            //   });
            toast.error(erro);

        }
        else {
            this.timeout_shipping = setTimeout(() => {
                this.get(id)
            }, 5000)
        }
    }



    calculateTotal() {
        let total = 0;
        this.props.cartlist.map((item) => {
            total += (item.product.header_info.discount ?

                discountCalculator(
                    item.product.header_info.price,
                    item.product.header_info.discount,
                    item.product.header_info.is_percentage_discount
                ) :

                undoMoneyMask(item.product.header_info.price)

            ) * parseInt(item.quantity)

        })

        return total.toFixed(2);

    }

    componentWillUnmount() {
        clearTimeout(this.timeout_shipping);
    }





    render() {
        const { cartlist, total, removeFromCart } = this.props;
        let endereco = this.props.address_select;
        let shipping = this.props.shipping;

        console.log(endereco);

        let logo = '';
        let brand = '';
        if (this.state.types.brand != '') {
            brand = this.state.types.brand.toUpperCase();
            console.log(brand);
            if (brand.includes('VISA')) {
                logo = visa;
            }
            else if (brand.includes('MASTER')) {
                logo = mastercard;
            }
            else if (brand.includes('HYPER')) {
                logo = hypercard;
            }
            else if (brand.includes('AME')) {
                logo = amex;
            }
            else if (brand.includes('ELO')) {
                logo = elo;
            }

            else if (brand.includes('DINERS')) {
                logo = diners_club;
            }
            else if (brand.includes('DISCOVER')) {
                logo = discover;
            }
            else if (brand.includes('AURA')) {
                logo = aura;
            }
            else if (brand.includes('JCB')) {
                logo = jcb;
            }

        }
        console.log(this.props.card)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                }}
            >
                <Navigate navigate={this.state.navigate} />
                <div
                    style={{
                        // background: product.header_info?.background_color_2
                        //   ? `linear-gradient(180deg, ${product.header_info?.background_color_1} 0%, ${product.header_info?.background_color_2} 100%)`
                        //   : product.header_info?.background_color_1,
                        display: "flex",
                    }}
                >
                    <Container
                        maxWidth="lg"
                        sx={{
                            paddingBottom: "2rem",
                            marginTop: 2
                            // check if there is a second color
                        }}
                    >

                        <div >
                            <BreadcrumbSection
                                {...{
                                    header_info: {},
                                    name: 'Carrinho'
                                }}
                            />
                        </div>

                        <div className="container d-none d-sm-block">

                            <div className={'breadcrumb-nav'}>
                                <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                                    activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                                    steps={[{
                                        title: 'Carrinho',
                                        href: '/carrinho',
                                    },
                                    {
                                        title: 'Endereço',
                                        href: '/carrinho/address'
                                    },
                                    {
                                        title: 'Entrega',
                                        href: '/carrinho/delivery'
                                    },
                                    {
                                        title: 'Pagamento',
                                        href: '/carrinho/payment'
                                    },
                                    {
                                        title: 'Resumo'
                                    },
                                    ]} activeStep={4} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-9">
                                <h2 className="checkout-title" style={{ marginTop: 20 }}>Informações do Pedido:</h2>

                                <div className="row mt-2">
                                    <div className="col-12 col-md-6  mt-1" >
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>Enviar Para: <Link style={{ fontSize: '1.1rem', marginLeft: 20, color: '#007bff' }} to={`/carrinho/address`}>Alterar</Link></p>
                                        <p className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1rem', fontWeight: '400' }}>
                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select?.address}, {this.props.address_select?.number}</div>

                                            {this.props.address_select?.complement != null && this.props.address_select?.complement != '' && <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select?.complement}</div>}

                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select?.neighborhood}, {this.props.address_select?.city} - {this.props.address_select?.state}</div>
                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select?.zip}</div>
                                        </p>
                                    </div>
                                    {this.props.address_charge != undefined && this.props.address_charge.id != undefined && <div className="col-12 col-md-6  mt-1" >
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>Endereço de Cobrança: <a onClick={(e) => { e.preventDefault(); this.setState({ modal_open: true }) }} href="#" style={{ fontSize: '1.1rem', marginLeft: 20, color: '#007bff' }} >Alterar</a></p>
                                        <p className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1rem', fontWeight: '400' }}>
                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_charge.address}, {this.props.address_charge.number}</div>

                                            {this.props.address_charge?.complement != null && this.props.address_charge.complement != '' && <div style={{ marginBottom: '0.5rem' }}>{this.props.address_charge.complement}</div>}

                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_charge?.neighborhood}, {this.props.address_charge?.city} - {this.props.address_charge?.state}</div>
                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_charge?.zip}</div>
                                        </p>
                                    </div>}
                                </div>
                                <div className="row mt-2">

                                    <div className="col-12 col-md-6  mt-1" hidden={this.state.precos_prazos.length == 0}>
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>Opção de entrega:</p>

                                        {this.state.precos_prazos.map((item, id) => (<div key={id} hidden={this.state.precos_prazos.length == 0} className="d-flex">
                                            <div>
                                                <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20 }}>
                                                    <input type="radio"
                                                        id={"shipping" + id}
                                                        name="shipping"
                                                        className="custom-control-input"
                                                        onChange={(e) => {
                                                            console.log(item);
                                                            this.props.changeState({ shipping: item })

                                                        }}
                                                        defaultChecked={this.props.shipping.delivery_method_id == item.delivery_method_id}
                                                    />
                                                    <label className="custom-control-label" htmlFor={"shipping" + id}>{item.description}</label>
                                                </div>
                                            </div>
                                            {item.retirada == true && <div><span style={{ color: 'green' }}>Grátis</span> - Disponível para retirada amanhã a partir das 08:00</div>}
                                            {item.retirada != true && <div>{moneyMask(item.final_shipping_cost)} - {item.delivery_estimate_business_days} {item.delivery_estimate_business_days == 1 ? 'dia útil' : 'dias úteis'}</div>}
                                        </div>
                                        ))}
                                    </div>
                                    {this.props.card.id && this.props.card.type != '' && <div className="col-12 col-md-6  mt-1" >
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>Forma de Pagamento: <Link style={{ fontSize: '1.1rem', marginLeft: 20, color: '#007bff' }} to={`/carrinho/payment`}>Alterar</Link></p>
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>
                                            <div className="d-flex">

                                                <div className="d-flex align-items-center">

                                                    <img src={visa} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                    <img src={mastercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                    <img src={amex} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                    <img src={elo} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                    <img src={hypercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />

                                                    <img src={diners_club} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('DINERS') ? 'block' : 'none' }} />
                                                    <img src={discover} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('DISCOVER') ? 'block' : 'none' }} />
                                                    <img src={jcb} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('JCB') ? 'block' : 'none' }} />
                                                    <img src={aura} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.card.brand.toUpperCase().includes('AURA') ? 'block' : 'none' }} />
                                                </div>
                                                <div className="align-items-center" style={{ marginLeft: 0, fontWeight: 400 }}>

                                                    <p style={{ color: 'black', marginBottom: '1rem' }}>{this.props.card.brand.toUpperCase()} <b style={{ marginLeft: 15 }}>(Crédito)</b></p>

                                                    <p style={{ margin: 0, color: 'black' }}> •••• {this.props.card.last_four_digits}</p>

                                                </div>
                                            </div>
                                            <p style={{ margin: 0, color: 'black', marginTop: 10 }}><span className="checkout-title" style={{ marginTop: 0, fontSize: '1rem', color: '#333' }}>Parcelas:</span> <span style={{ fontWeight: '400' }}>{this.props.installment + 'x de ' + moneyMask((parseFloat(this.calculateTotal()) + parseFloat(this.props.shipping.final_shipping_cost)) / this.props.installment)}</span></p>


                                        </p>
                                    </div>}
                                </div>
                                <hr />

                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Produtos:</h2>

                                <div className="row">
                                    {/* <div className="col-12 col-lg-6" hidden={this.state.loading == true || this.state.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Enviar Para:</h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                    {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                                </h3>
                                            </div> */}

                                    <div className="col-12 col-lg-12" >
                                        <table className={style.customTable}>
                                            <thead>
                                                <tr>
                                                    <th>Foto</th>
                                                    <th>Produto</th>
                                                    <th>Preço</th>
                                                    <th>Quantidade</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {cartlist.length > 0 ?
                                                    cartlist.map((item, index) =>
                                                        <tr key={index} style={{ position: 'relative' }}>
                                                            <td className="product-col">
                                                                <div className="product">

                                                                    <Link style={{ width: 100, height: 100 }} to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                                        <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={STORAGE_URL + item.product.header_info.header_images[0].image} alt="Product" />
                                                                    </Link>
                                                                </div>

                                                            </td>
                                                            <td className="product-col">
                                                                <div className="product">


                                                                    <p className="product-title" style={{ marginBottom: 0 }}>
                                                                        <Link to={`/produto/${item.product.name.toLowerCase().replaceAll(" ", "-")}`}>{item.product.name}</Link>
                                                                    </p>
                                                                </div>
                                                            </td>

                                                            <td className="price-col">
                                                                {item.product.header_info.discount && <CustomText
                                                                    text={item.product.header_info.price}
                                                                    bold
                                                                    style={{
                                                                        textDecoration: "line-through",
                                                                        marginRight: 1
                                                                    }}
                                                                    color="#878787"
                                                                    size="1xrem"
                                                                />}
                                                                {moneyMask(item.product.header_info.discount ?

                                                                    discountCalculator(
                                                                        item.product.header_info.price,
                                                                        item.product.header_info.discount,
                                                                        item.product.header_info.is_percentage_discount
                                                                    ) :

                                                                    item.product.header_info.price

                                                                )}
                                                            </td>

                                                            <td className="quantity-col">


                                                                <div hidden={this.state.loading == true} className="cart-product-quantity" >



                                                                    <div className="input-qty-custom">
                                                                        {item.quantity}
                                                                    </div>


                                                                </div>

                                                            </td>

                                                            <td className="total-col">
                                                                {moneyMask((item.product.header_info.discount ?

                                                                    discountCalculator(
                                                                        item.product.header_info.price,
                                                                        item.product.header_info.discount,
                                                                        item.product.header_info.is_percentage_discount
                                                                    ) :

                                                                    undoMoneyMask(item.product.header_info.price)

                                                                ) * parseInt(item.quantity))}
                                                            </td>



                                                        </tr>
                                                    ) :
                                                    <tr>
                                                        <th colSpan="4">
                                                            <p className="pl-2 pt-1 pb-1"> Não há produtos no carrinho </p>
                                                        </th>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>

                                        {/* {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>} */}

                                    </div>


                                </div>
                            </div>

                            <aside className="col-lg-3 col-sm-12">
                                <div className="summary summary-cart">
                                    <p className="summary-title">Seu pedido</p>
                                    <table className="table table-summary">
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {cartlist.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ color: 'black' }}><Link to="#">{item.quantity}x {item.product.name}</Link></td>
                                                    <td style={{ color: 'black' }}>
                                                        {moneyMask((parseFloat((item.product.header_info.discount ?

                                                            discountCalculator(
                                                                item.product.header_info.price,
                                                                item.product.header_info.discount,
                                                                item.product.header_info.is_percentage_discount
                                                            ) :

                                                            undoMoneyMask(item.product.header_info.price)

                                                        )) * parseInt(item.quantity)).toFixed(2))}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <hr />
                                    <p className=""><b>Subtotal:</b> {moneyMask(this.calculateTotal())}</p>
                                    {this.props.shipping && <p className=""><b>Entrega: </b> {moneyMask(this.props.shipping.final_shipping_cost)}</p>}
                                    <hr />
                                    {this.state.loading == false && <>

                                        {console.log(parseFloat(this.calculateTotal()), parseFloat(this.props.shipping.final_shipping_cost))}
                                        {this.props.shipping && <p className=""><b>Total: </b> {moneyMask((parseFloat(this.calculateTotal()) + parseFloat(this.props.shipping.final_shipping_cost)).toFixed(2))}</p>}


                                        <hr />



                                        {this.state.loading_order==false ? <button style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.props.user.addresses.length == 0 || this.state.loading_save == true || this.props.address_select.id == undefined || this.props.shipping?.delivery_method_id == undefined || this.props.card?.id == undefined} 
                                            className="btn btn-outline-primary btn-order btn-block"
                                            onClick={(e) => {
                                                this.store_order()

                                            }}
                                        >
                                            {<span className="btn-text">Finalizar </span>}
                                            { <span className="btn-hover-text"><ArrowRight /> </span>}
                                        </button> : <div className='d-flex justify-content-center'>
                                        <CircularProgress className="btn-text" size={20}/>
                                            </div>}
                                    </>}

                                </div>

                                {this.state.loading_order==false &&<Link to={`${process.env.PUBLIC_URL}/carrinho/payment`} className="btn btn-outline-dark btn-block mt-3"><span>Voltar</span> <ArrowLeft /></Link>}
                            </aside>
                        </div>

                    </Container>

                </div>
                <Modal
                    isOpen={this.state.modal_open}
                    onRequestClose={() => this.setState({ modal_open: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    id="modal_address"
                >
                    
                    <div hidden={this.state.loading == true || this.props.user.addresses.length == 0} style={{position:'relative'}}>
                        <h2 className="checkout-title" >Selecione um Endereço de Cobrança <AddressBook /> </h2>
                        <div style={{ position: 'absolute', top: -10, right: 0, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <Close/>
                    </div>
                        <div className="row">

                            {this.props.user.addresses.map((item, id) => (
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-1" key={id}>
                                    <div onClick={() => {
                                        this.props.mudarDados({ address_charge: item })
                                        this.setState({modal_open:false});
                                    }} className="card select_address" style={{
                                        border: this.props.address_charge.id == item.id ? '1px solid green' : '1px solid lightgrey',
                                        padding: '5px',
                                        borderRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        height: '100%',
                                        maxWidth: 260
                                    }}>
                                        <div className="card-body" style={{
                                            padding: 0, display: 'flex',
                                            justifyContent: 'center',
                                        }}>

                                            <p className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1rem', fontWeight: '400' }}>
                                                <span style={{ marginBottom: '0.5rem' }}>{item.address.substring(0, 25)}{item.address.length > 25 ? '.' : ''} {item.number}</span>

                                                {item.complement != null && item.complement != '' && '\n'}
                                                {item.complement != null && item.complement != '' && <span style={{ marginBottom: '0.5rem' }}>{item.complement.substring(0, 25)}{item.complement.length > 25 ? '.' : ''}</span>}

                                                {'\n'}
                                                <span style={{ marginBottom: '0.5rem' }}>{item.neighborhood.substring(0, 25)}{item.neighborhood.length > 25 ? '.' : ''}{'\n'}</span>
                                                <span style={{ marginBottom: '0.5rem' }}>{item.city.substring(0, 25)}{item.city.length > 25 ? '.' : ''} - {item.state}{'\n'}</span>
                                                <span style={{ marginBottom: '0.5rem' }}>{item.zip}</span>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            ))}

                        </div>

                    </div>
                    {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '2rem' }}><CircularProgress /></div>}
                    <hr />
                    <div style={{ border: '1px solid lightgrey', padding: 15, borderRadius: 5 }}>

                        <h2 onClick={() => this.setState({ endereco_hidden: !this.state.endereco_hidden })} className="checkout-title show_hide" style={{ display: 'flex', alignItems: 'center', fontSize: '1.4rem', marginTop: '1rem', justifyContent: 'space-between' }}>Adicione um Novo Endereço {this.state.endereco_hidden != false ? <ArrowDown /> : <ArrowUp />} </h2>
                        <div hidden={this.state.endereco_hidden}>

                            <div style={{
                                color: '#721c24',
                                backgroundColor: '#f8d7da',
                                borderColor: '#f5c6cb'
                            }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                            </div>
                            <label className='label_form'>Cep <span style={{ color: 'red' }}>*</span></label>
                            <input value={this.state.zip} onChange={(e) => {
                                this.setState({ zip: maskCep(e.target.value) });
                                let text = e.target.value.replace('-', '');
                                if (text.length == 8) {
                                    this.buscar_endereco(text);
                                }
                            }} type="text" placeholder="Cep" className="form-control" required />

                            <label className='label_form'>Rua <span style={{ color: 'red' }}>*</span></label>
                            <input value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} type="text" placeholder="Rua" className="form-control" required />

                            <label className='label_form'>Número <span style={{ color: 'red' }}>*</span></label>
                            <input value={this.state.number} onChange={(e) => this.setState({ number: e.target.value })} type="text" placeholder="Número" className="form-control" required />

                            <label className='label_form'>Complemento</label>
                            <input value={this.state.complement} onChange={(e) => this.setState({ complement: e.target.value })} type="text" placeholder="Complemento" className="form-control" />

                            <label className='label_form'>Bairro <span style={{ color: 'red' }}>*</span></label>
                            <input value={this.state.neighborhood} onChange={(e) => this.setState({ neighborhood: e.target.value })} type="text" placeholder="Bairro" className="form-control" required />

                            <label className='label_form'>Cidade <span style={{ color: 'red' }}>*</span></label>
                            <input value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} type="text" placeholder="Cidade" className="form-control" required />

                            <label className='label_form'>Estado (Sigla) <span style={{ color: 'red' }}>*</span></label>
                            <input value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} type="text" placeholder="Estado (Sigla)" className="form-control" required />



                            <div className='d-flex justify-content-end'>

                                {this.state.loading_save == false && <button onClick={() => {
                                    this.store();
                                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                    <span>Salvar Endereço</span>
                                    <i className="far fa-save"></i>
                                </button>}
                                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress /></div>}
                            </div>

                        </div>

                    </div>

                </Modal>
            </div>


        )
    }

     async store() {
            this.setState({ loading_save: true })
    
            const res = await post("addresses/store", JSON.stringify({
                address: this.state.address,
                number: this.state.number,
                neighborhood: this.state.neighborhood,
                city: this.state.city,
                complement: this.state.complement,
                state: this.state.state,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                zip: this.state.zip,
    
            }));
            if (res.httpCode === 200) {
                console.log(res.carts)
                this.setState({
                    loading_save: false, endereco_hidden: true, zip: '',
                    address: '',
                    number: '',
                    neighborhood: '',
                    city: '',
                    state: '',
                    latitude: -1,
                    longitude: -1,
                    complement: ''
                })
                this.props.mudarDados({ user: res.user, address_charge: res.user.addresses.filter((item) => item.default == true)[0] })
    
    
            } else {
                if (res.errors != null || res.error != null) {
                    let errors = Object.values(res.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
    
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                    toast.error(erro);
    
                }
                this.setState({ loading_save: false })
            }
            // setLoadingAdd(false);
    
    
        }

    buscar_endereco(cep) {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    address: resp.logradouro,
                    neighborhood: resp.bairro,
                    city: resp.localidade,
                    state: resp.uf,
                    number: ''

                }, () => {
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }
}

export const mapStateToProps = (state) => {
    return ({
        cartlist: state.AppReducer.carts,
        shipping: state.AppReducer.shipping,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        delivery: state.AppReducer.delivery,
        address_select: state.AppReducer.address_select,
        address_charge: state.AppReducer.address_charge,
        shippings: state.AppReducer.shippings,
        shipping: state.AppReducer.shipping,
        card: state.AppReducer.card,
        installment: state.AppReducer.installment,


    })
}

export default connect(mapStateToProps, { mudarDados })(CartResume);