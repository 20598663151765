import { useEffect, useState } from "react";
import CustomText from "components/CustomText";
import { CaretRight, House } from "@phosphor-icons/react";
import EditFormSobreNos from "./EditForm";
import { toast } from "react-toastify";
import { get, post } from "utilities/requests";
import VerProdutoPage from "pages/VerProduto";
import { useNavigate, useParams } from "react-router-dom";
import { handleAddProduct, handleEditProduct } from "./actions";

export default function AdminUsuariosFormPage({ editing_mode = false }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [show_preview, setShowPreview] = useState(true);
  const [ready_to_render, setReadyToRender] = useState(false);

  // section states
  // secao 1
  const [header_info, setHeaderInfo] = useState({});
  const [header_images, setHeaderImages] = useState([]);
  const [deleted_header_images, setDeletedHeaderImages] = useState([]);

  // secao 2
  const [description, setDescription] = useState({
    top_text: "",
    bottom_text: "",
    top_image: {},
    bottom_image: {},
    background_image: {},
  });

  // secao 3
  const [product_comparison, setProductComparison] = useState({
    table: {},
  });

  // secao 4
  const [nutritional_info, setNutritionalInfo] = useState({
    subtitle: "",
    description: "",
    table: {},
  });

  // secao 5
  const [faq, setFaq] = useState({
    questions: [],
  });

  // auto resize preview
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 968) {
        setShowPreview(false);
      } else {
        setShowPreview(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getProduct = async () => {
    const res = await post("products/get_by_id", JSON.stringify({ id: id }));
    if (res.httpCode === 200) {
      const product = res.product;

      // secao 1
      setHeaderInfo(product.header_info);
      setHeaderImages(product.header_info.header_images);

      // secao 2
      setDescription(product.description);

      // secao 3
      setProductComparison(product.product_comparison);

      // secao 4
      setNutritionalInfo(product.nutritional_info);

      // secao 5
      setFaq(product.faq);

      setReadyToRender(true);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (editing_mode) getProduct();
    else setReadyToRender(true);
  }, [id]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* form */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "70%" : "100%",
          transition: "500ms ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.4rem",
                fontSize: "1.4rem",
                alignItems: "center",
              }}
            >
              <House weight="fill" />
              <CustomText
                text={editing_mode ? "Editar usuário" : "Adicionar usuário"}
                size="1.4rem"
                bold
                style={{
                  margin: "1rem 0",
                }}
              />
            </div>
            <label
              style={{
                textTransform: "uppercase",
                padding: "0.4rem 1rem",
                backgroundColor: "#333333",
                color: "#FFFFFF",
                cursor: "pointer",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => {
                setShowPreview(!show_preview);
              }}
            >
              {show_preview ? (
                <>
                  <CaretRight weight="fill" />
                  <span>Esconder preview</span>
                </>
              ) : (
                <>
                  <CaretRight
                    weight="fill"
                    style={{
                      transform: "rotate(180deg)",
                      fontSize: "1.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <span>Mostrar preview</span>
                </>
              )}
            </label>
          </div>
          <CustomText
            text="<b>Atenção: preview 100% fiel ao resultado final."
            size="1rem"
            color="#4d4d4d"
          />
        </div>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",

            padding: "1vw",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            alignItems: "flex-end",
          }}
          onSubmit={async (e) => {
            e.preventDefault();
            const data = {
              id: editing_mode ? id : null,
              header_info,
              header_images,
              deleted_header_images: editing_mode ? deleted_header_images : [],
              description,
              product_comparison,
              nutritional_info,
              faq,
            };
            if (editing_mode) {
              handleEditProduct(data);
            } else {
              handleAddProduct(data);
            }
          }}
          onInvalid={(e) => {
            e.preventDefault();
            const input_name = e.target.name || e.target.placeholder;
            toast.warn(`Preencha o campo "${input_name}"`);
          }}
        >
          {ready_to_render && (
            <EditFormSobreNos
              {...{
                // secao 1
                header_info,
                setHeaderInfo,
                header_images,
                setHeaderImages,
                deleted_header_images,
                setDeletedHeaderImages,

                // secao 2
                description,
                setDescription,

                // secao 3
                product_comparison,
                setProductComparison,

                // secao 4
                nutritional_info,
                setNutritionalInfo,

                // secao 5
                faq,
                setFaq,
              }}
            />
          )}
          <input
            type="submit"
            style={{
              textTransform: "uppercase",
              padding: "0.4rem 1rem",
              backgroundColor: "#333333",
              color: "#FFFFFF",
              cursor: "pointer",
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              width: "fit-content",
            }}
            value={editing_mode ? "Editar produto" : "Adicionar produto"}
          />
        </form>
      </div>

      {/* preview */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "100%" : "0%",
          overflowY: "auto",
          borderLeft: "1px solid #ccc",
          transition: "500ms ease",
        }}
      >
        <VerProdutoPage
          {...{
            preview_mode: true,
            preview_content: {
              header_info: {
                ...header_info,
                header_images,
              },
              description,
              product_comparison,
              nutritional_info,
              faq,
            },
          }}
        />
      </div>
    </div>
  );
}
