import { Box, Container } from "@mui/material";
import BreadcrumbSection from "./sections/Breadcrumb";
import ProductImagesSection from "./sections/ProductImages";
import ProductInfoSection from "./sections/ProductInfo";
import AboutProductSection from "./sections/AboutProduct";
import NutritionalInfoSection from "./sections/NutritionalInfo";
import FAQProductSection from "./sections/FaqProduct";
import ReviewSection from "./sections/Reviews";
import RelatedProductsSection from "./sections/RelatedProducts";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";
import ProductsComparisonSection from "./sections/ProductsComparison";
import { useParams } from "react-router-dom";

export default function VerProdutoPage({
  preview_mode = false,
  preview_content,
}) {
  const { name } = useParams();
  const [product, setProduct] = useState({});
  const [ready_to_render, setReadyToRender] = useState(false);

  const getProductContent = async () => {
    const res = await get(`products/get_by_name/${name}`);
    setProduct(res.product);
    setReadyToRender(true);
  };

  useEffect(() => {
    if (preview_mode) {
      setProduct(preview_content);
      setReadyToRender(true);
    } else {
      getProductContent();
    }
  }, []);

  useEffect(() => {
    setProduct(preview_content);
  }, [preview_content]);


  return (
    ready_to_render && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            background: product.header_info?.background_color_2
              ? `linear-gradient(180deg, ${product.header_info?.background_color_1} 0%, ${product.header_info?.background_color_2} 100%)`
              : product.header_info?.background_color_1,
            display: "flex",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              paddingBottom: "2rem",
              marginTop:2
              // check if there is a second color
            }}
          >
            <BreadcrumbSection
              {...{
                header_info: product.header_info,
                name:product.name
              }}
            />

            {/* Product Header */}
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: "8vw",
              }}
            >
              <ProductImagesSection
                {...{
                  header_images: product.header_info.header_images,
                }}
              />
              <ProductInfoSection
                {...{
                  header_info: product.header_info,
                  id:product.id
                }}
              />
            </Box>
          </Container>
        </div>

        <AboutProductSection
          {...{
            description: product.description,
          }}
        />

        <ProductsComparisonSection
          {...{
            product_comparison: product.product_comparison,
          }}
        />

        <NutritionalInfoSection
          {...{
            nutritional_info: product.nutritional_info,
          }}
        />

        <FAQProductSection
          {...{
            faq: product.faq,
          }}
        />

        <ReviewSection />

        <RelatedProductsSection />
      </div>
    )
  );
}
