import CustomText from "components/CustomText";
import RateSection from "./Rate";
import black_friday_img from "assets/static/ProductsPage/blackfriday.svg";
import { Checkbox, FormControlLabel, checkboxClasses } from "@mui/material";
import CustomButton from "components/CustomButton";
import { discountCalculator, moneyMask } from "utilities/helpers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { mudarDados } from "components/actions/AppActions";
import { post } from "utilities/requests";

export default function ProductInfoSection({ header_info,id }) {
  const [discounted_price, setDiscountedPrice] = useState(0);
  const [qty,setQty] = useState(1);
const [loading_add, setLoadingAdd] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setDiscountedPrice(
      discountCalculator(
        header_info.price,
        header_info.discount,
        header_info.is_percentage_discount
      )
    );
  }, [header_info]);

  const addToCart = async () => {
      setLoadingAdd(true);
      const res = await post("cart/add", JSON.stringify({ product_id: id, quantity: qty, random_id: localStorage.getItem('random_id') }));
      if (res.httpCode === 200) {
        console.log(res.carts)
        dispatch(mudarDados({ carts:res.carts}))
  
  
      } else {
        if (res.errors != null || res.error != null) {
          let errors = Object.values(res.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
  
          //   setTimeout(()=>{
          //     this.get_processo();
          //   });
          toast.error(res.erro);
  
        }
      }
      setLoadingAdd(false);
  
    };
  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        marginTop: "1rem",
        justifyContent: "flex-end",
        margin: "0 auto",
        gap: "0.4rem",
      }}
    >
      <RateSection
        {...{
          header_info,
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1rem",
        }}
      >
        {/* product's name */}
        <CustomText
          text={header_info.name}
          size="1.9rem"
          bold
          color={header_info.primary_text_color}
        />

        {/* product's subtitle */}
        <CustomText
          text={header_info.subtitle}
          size="0.95rem"
          bold
          style={{
            marginBottom: "0.4rem",
          }}
          color={header_info.primary_text_color}
        />

        {/* product's description */}
        <CustomText
          text={header_info.description}
          size="1rem"
          color={header_info.secondary_text_color}
        />
      </div>

      <div>
        <img
          src={black_friday_img}
          alt="Black Friday"
          style={{
            width: "18.4rem",
            objectFit: "contain",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <CustomText
          text={header_info.price}
          bold
          style={{
            textDecoration: "line-through",
          }}
          color={header_info.secondary_text_color}
          size="1.28rem"
        />
        <CustomText
          text={moneyMask(discounted_price)}
          bold
          size="1.28rem"
          color={header_info.primary_text_color}
        />
      </div>

      <select
        style={{
          marginBottom: "1rem",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
        onChange={(e)=>{
          setQty(e.target.value)
        }}
      >
        {/* generate options based on the product's quantity limit */}
        {Array.from(
          { length: header_info.purchase_quantity_limit },
          (_, i) => i + 1
        ).map((item, index) => (
          <option key={index} value={item}>
            {item} un. R${" "}
            {/* {moneyMask(undoMoneyMask(header_info.price) * (index + 1))} */}
            {moneyMask(discounted_price * (index + 1))}
          </option>
        ))}
      </select>

      <FormControlLabel
        control={
          <Checkbox
            sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: header_info.accent_color_1,
              },
            }}
          />
        }
        label={
          <CustomText
            text={
              "<b>Leve junto</b> Nome do outro produto por <b>+ R$ 00,00</b>"
            }
            size="0.9rem"
            color={header_info.primary_text_color}
          />
        }
        sx={{
          marginBottom: "1rem",
        }}
      />

      <CustomButton
        text={"Comprar"}
        style={{
          marginBottom: "1rem",
        }}
        bgColor={header_info.accent_color_1}
        textColor={"white"}
        loading={loading_add}
        onClick={(e) => {
          e.stopPropagation()
          addToCart();
        }}
      />

      <CustomText
        text={"Sua compra contribui com <b>Be Generous.</b> <a>saiba mais</a>"}
        size="0.9rem"
        color={header_info.secondary_text_color}
      />
    </div>
  );
}
