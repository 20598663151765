import { CircularProgress } from "@mui/material";

export default function CustomButton({
  text = "insira o text",
  onClick = () => {},
  style,
  disabled = false,
  icon = null,
  bgColor = "#333333",
  textColor = "#FFFFFF",
  loading = false
}) {
  return (
    <button
      style={{
        textTransform: "uppercase",
        padding: "1.2rem",
        backgroundColor: bgColor,
        color: textColor,
        fontWeight: "bold",
        ...style,
      }}
      onClick={(e)=>{
        if(loading==true){
          return;
        }
        onClick(e)
      }}
      disabled={disabled}
    >
      {loading==false && icon && icon}
      {loading==true?<CircularProgress size={20}/>: <span>{text}</span>}
    </button>
  );
}
