import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import { Box, CircularProgress, Container } from "@mui/material";

import Stepper from 'react-stepper-horizontal';
import { discountCalculator, maskCep, moneyMask, undoMoneyMask } from 'utilities/helpers';
import Breadcrumb from 'components/Header/breadcrumb';
import PageHeader from 'components/Header/page-header';
import { mudarDados } from 'components/actions/AppActions';
import BreadcrumbSection from 'pages/VerProduto/sections/Breadcrumb';
import style from "./style.module.css";
import { STORAGE_URL } from 'utilities/variables';
import { AddressBook, ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Bag, Truck, XCircle } from '@phosphor-icons/react';
import './style.css';
import CustomText from 'components/CustomText';
import { get, post } from 'utilities/requests';
import { toast } from 'react-toastify';
import Navigate from 'components/Navigate/navigate';
class CartDelivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // shipping: { delivery_method_id: '', final_shipping_cost: 0 },
            update: true,
            loading_frete: false,
            msgErro: '',
            loading_add: {},
            zip: '',
            address: '',
            number: '',
            neighborhood: '',
            city: '',
            state: '',
            latitude: -1,
            longitude: -1,
            complement: '',
            loading_save: false,
            address_select: {},
            endereco_hidden: true,
            precos_prazos: [],
            loading: true

        }
    }

    componentDidMount() {

        if (this.props.token == null || this.props.user?.id == undefined || this.props.cartlist.length == 0) {
            this.setState({ navigate: '/carrinho' })
        }
        else if (this.props.address_select?.id == undefined) {
            let address = JSON.parse(localStorage.getItem('address_select') || '{}');
            if (address?.id != undefined && this.props.user.addresses.filter((item) => item.id == address.id).length > 0) {
                this.props.mudarDados({ address_select: address,address_charge:address })
                this.get_shipping(address.id)
            }
            else {
                this.setState({ navigate: '/carrinho' })

            }

        }
        else {
            this.get_shipping(this.props.address_select?.id)

        }
        // quantityInputs();

    }

    async get_shipping(id) {
        const res = await post("cart/get_shipment_prices", JSON.stringify({
            produtos: this.props.cartlist.map((item) => {
                return {
                    id: item.product.id,
                    largura: item.product.largura,
                    altura: item.product.altura,
                    comprimento: item.product.comprimento,
                    peso: item.product.peso,
                    sale_price: item.product.header_info.discount ?

                        discountCalculator(
                            item.product.header_info.price,
                            item.product.header_info.discount,
                            item.product.header_info.is_percentage_discount
                        ) :

                        null,
                    price: undoMoneyMask(item.product.header_info.price),
                    qty: item.quantity
                }
            }),
            address_id: id,
        }));
        if (res.httpCode === 200 && res.errors == undefined) {
            let precos_prazos = [];
            for (let i = 0; i < res.precos_prazos.length; i++) {
                precos_prazos.push({
                    final_shipping_cost: res.precos_prazos[i].vltotal,
                    delivery_estimate_business_days: res.precos_prazos[i].prazo,
                    retirada: res.precos_prazos[i].retirada,
                    entrega_local: res.precos_prazos[i].entrega_local,
                    volumes: res.precos_prazos[i].packages ? res.precos_prazos[i].packages : null,
                    agency_id: res.precos_prazos[i].agency_id ? res.precos_prazos[i].agency_id : null,
                    delivery_method_id: res.precos_prazos[i].id ? res.precos_prazos[i].id : Math.random(),
                    description: res.precos_prazos[i].entrega_local == true ? 'Entrega local' : (res.precos_prazos[i].retirada == true
                        ? 'Retirada no estabelecimento' : res.precos_prazos[i].description),
                    volumes_package:res.volumes
                })
            }
            console.log(precos_prazos)
            this.props.mudarDados({ shippings: precos_prazos, shipping: precos_prazos[0] ? precos_prazos[0] : {} })
            this.setState({ loading: false })

        }
        else if (res.errors != undefined) {
            let errors = Object.values(res.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                else
                    erro += errors[i];
            }
            console.log(erro);

            //   setTimeout(()=>{
            //     this.get_processo();
            //   });
            toast.error(erro);

        }
        else {
            this.timeout_shipping = setTimeout(() => {
                this.get_shipping(id)
            }, 5000)
        }
    }



    calculateTotal() {
        let total = 0;
        this.props.cartlist.map((item) => {
            total += (item.product.header_info.discount ?

                discountCalculator(
                    item.product.header_info.price,
                    item.product.header_info.discount,
                    item.product.header_info.is_percentage_discount
                ) :

                undoMoneyMask(item.product.header_info.price)

            ) * parseInt(item.quantity)

        })

        return total.toFixed(2);

    }

    componentWillUnmount() {
        clearTimeout(this.timeout_shipping);
    }





    render() {
        const { cartlist, total, removeFromCart } = this.props;
        console.log(this.props.user)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                }}
            >
                <Navigate navigate={this.state.navigate} />
                <div
                    style={{
                        // background: product.header_info?.background_color_2
                        //   ? `linear-gradient(180deg, ${product.header_info?.background_color_1} 0%, ${product.header_info?.background_color_2} 100%)`
                        //   : product.header_info?.background_color_1,
                        display: "flex",
                    }}
                >
                    <Container
                        maxWidth="lg"
                        sx={{
                            paddingBottom: "2rem",
                            marginTop: 2
                            // check if there is a second color
                        }}
                    >

                        <div >
                            <BreadcrumbSection
                                {...{
                                    header_info: {},
                                    name: 'Carrinho'
                                }}
                            />
                        </div>

                        <div className="container d-none d-sm-block">

                            <div className={'breadcrumb-nav'}>
                                <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                                    activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                                    steps={[{
                                        title: 'Carrinho',
                                        href: '/carrinho',
                                    },
                                    {
                                        title: 'Endereço',
                                        href: '/carrinho/address'
                                    },
                                    {
                                        title: 'Entrega',
                                        href: '/carrinho/delivery'
                                    },
                                    {
                                        title: 'Pagamento',
                                        href: '/carrinho/payment'
                                    },
                                    {
                                        title: 'Resumo'
                                    },
                                    ]} activeStep={2} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className="checkout-title" >Entrega <Truck /> </h2>

                            </div>

                            <div className='col-lg-9 col-sm-12' >
                                <div className="row">
                                    <div className="col-12 col-sm-6 mt-1" hidden={this.state.loading == true || this.props.shippings.length == 0}>
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>Enviar Para:</p>
                                        {this.props.address_select?.id != undefined && <p className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1rem', fontWeight: '400' }}>
                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select.address}, {this.props.address_select.number}</div>

                                            {this.props.address_select.complement != null && this.props.address_select.complement != '' && <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select.complement}</div>}

                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select.neighborhood}, {this.props.address_select.city} - {this.props.address_select.state}</div>
                                            <div style={{ marginBottom: '0.5rem' }}>{this.props.address_select.zip}</div>
                                        </p>}
                                    </div>

                                    <div className="col-12 col-sm-6 mt-1" hidden={this.state.loading == true || this.props.shippings.length == 0}>
                                        <p style={{ marginTop: 0, fontWeight: 'bold' }}>Escolha uma opção de entrega para a sua região:</p>

                                        {this.props.shippings.map((item, id) => (
                                            <div key={id} hidden={this.props.shippings.length == 0} className="d-flex mb-2">
                                                <div>
                                                    <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20 }}>
                                                        <input type="radio"
                                                            id={"shipping" + id}
                                                            name="shipping"
                                                            className="custom-control-input"
                                                            onChange={(e) => {
                                                                console.log(item);
                                                                this.props.mudarDados({ shipping: item });
                                                            }}
                                                            defaultChecked={this.props.shipping.delivery_method_id == item.delivery_method_id}
                                                        />
                                                        <label className="custom-control-label" htmlFor={"shipping" + id}>{item.description}</label>
                                                    </div>
                                                </div>
                                                {item.retirada != true && <div>{item.final_shipping_cost == 0 ? <span style={{ color: 'green' }}>Grátis</span> : moneyMask(item.final_shipping_cost)} - {item.delivery_estimate_business_days} {item.delivery_estimate_business_days == 1 ? 'dia útil' : 'dias úteis'}</div>}

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '2rem' }}><CircularProgress /></div>}




                            </div>
                            <aside className="col-lg-3 col-sm-12">
                                <div className="summary summary-cart">
                                    <p className="summary-title">Seu pedido</p>
                                    <table className="table table-summary">
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {cartlist.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ color: 'black' }}><Link to="#">{item.quantity}x {item.product.name}</Link></td>
                                                    <td style={{ color: 'black' }}>
                                                        {moneyMask((parseFloat((item.product.header_info.discount ?

                                                            discountCalculator(
                                                                item.product.header_info.price,
                                                                item.product.header_info.discount,
                                                                item.product.header_info.is_percentage_discount
                                                            ) :

                                                            undoMoneyMask(item.product.header_info.price)

                                                        )) * parseInt(item.quantity)).toFixed(2))}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <hr />
                                    <p className=""><b>Subtotal:</b> {moneyMask(this.calculateTotal())}</p>
                                    {this.props.shipping && <p className=""><b>Entrega: </b> {moneyMask(this.props.shipping.final_shipping_cost)}</p>}
                                    <hr />
                                    {this.state.loading==false && <>

                                    {console.log(parseFloat(this.calculateTotal()), parseFloat(this.props.shipping.final_shipping_cost))}
                                    {this.props.shipping && <p className=""><b>Total: </b> {moneyMask((parseFloat(this.calculateTotal()) + parseFloat(this.props.shipping.final_shipping_cost)).toFixed(2))}</p>}


                                    <hr />



                                    <Link style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.props.user.addresses.length == 0 || this.state.loading_save == true || this.props.address_select.id == undefined || this.props.shipping?.delivery_method_id == undefined} to={`${process.env.PUBLIC_URL}/carrinho/payment`}
                                        className="btn btn-outline-primary btn-order btn-block"
                                        onClick={(e)=>{
                                            localStorage.setItem('shipping',JSON.stringify(this.props.shipping))
                                        }}
                                    >
                                        <span className="btn-text">Continuar </span>
                                        <span className="btn-hover-text"><ArrowRight /> </span>
                                    </Link>
                                    </>}

                                </div>

                                <Link to={`${process.env.PUBLIC_URL}/carrinho/address`} className="btn btn-outline-dark btn-block mt-3"><span>Voltar</span> <ArrowLeft /></Link>
                            </aside>
                        </div>

                    </Container>

                </div>
            </div>


        )
    }
}

export const mapStateToProps = (state) => {
    return ({
        cartlist: state.AppReducer.carts,
        shipping: state.AppReducer.shipping,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        delivery: state.AppReducer.delivery,
        address_select: state.AppReducer.address_select,
        shippings: state.AppReducer.shippings,
        shipping: state.AppReducer.shipping,


    })
}

export default connect(mapStateToProps, { mudarDados })(CartDelivery);