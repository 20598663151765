import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import { Box, CircularProgress, Container } from "@mui/material";

import Stepper from 'react-stepper-horizontal';
import { discountCalculator, moneyMask, undoMoneyMask } from 'utilities/helpers';
import Breadcrumb from 'components/Header/breadcrumb';
import PageHeader from 'components/Header/page-header';
import { mudarDados } from 'components/actions/AppActions';
import BreadcrumbSection from 'pages/VerProduto/sections/Breadcrumb';
import style from "./style.module.css";
import { STORAGE_URL } from 'utilities/variables';
import { ArrowRight, Bag, ShoppingBag, Truck, XCircle } from '@phosphor-icons/react';
import './style.css';
import CustomText from 'components/CustomText';
import { post } from 'utilities/requests';
import { toast } from 'react-toastify';
class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // shipping: { delivery_method_id: '', final_shipping_cost: 0 },
            update: true,
            cep: '',
            loading_frete: false,
            msgErro: '',
            loading_add: {}

        }
    }

    componentDidMount() {
        localStorage.removeItem('shipping')
        localStorage.removeItem('address_select')
        localStorage.removeItem('card')
        localStorage.removeItem('installment')



        // quantityInputs();
    }




    async addToCart(id, qty) {
        let loadings = { ...this.state.loading_add }
        loadings[id] = true;
        this.setState({ loading_add: loadings })
        const res = await post("cart/add", JSON.stringify({ product_id: id, quantity: qty, random_id: localStorage.getItem('random_id') }));
        if (res.httpCode === 200) {
            console.log(res.carts)
            this.props.mudarDados({ carts: res.carts })


        } else {
            if (res.errors != null || res.error != null) {
                let errors = Object.values(res.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);

                //   setTimeout(()=>{
                //     this.get_processo();
                //   });
                toast.error(erro);

            }
        }
        loadings = { ...this.state.loading_add }
        loadings[id] = false;
        this.setState({ loading_add: loadings })

    };


    calculateTotal(){
        let total=0;
        this.props.cartlist.map((item)=>{
            total+=(item.product.header_info.discount ?

                discountCalculator(
                    item.product.header_info.price,
                    item.product.header_info.discount,
                    item.product.header_info.is_percentage_discount
                ) :
    
                undoMoneyMask(item.product.header_info.price)
    
            )*parseInt(item.quantity)
        })

        return total;
        
    }




    render() {
        const { cartlist, total, removeFromCart } = this.props;
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                }}
            >
                <div
                    style={{
                        // background: product.header_info?.background_color_2
                        //   ? `linear-gradient(180deg, ${product.header_info?.background_color_1} 0%, ${product.header_info?.background_color_2} 100%)`
                        //   : product.header_info?.background_color_1,
                        display: "flex",
                    }}
                >
                    <Container
                        maxWidth="lg"
                        sx={{
                            paddingBottom: "2rem",
                            marginTop: 2
                            // check if there is a second color
                        }}
                    >

                        <div >
                            <BreadcrumbSection
                                {...{
                                    header_info: {},
                                    name: 'Carrinho'
                                }}
                            />
                        </div>

                        <div className="container d-none d-sm-block">

                            <div className={'breadcrumb-nav'}>
                                <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                                    activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                                    steps={[{
                                        title: 'Carrinho',
                                        href: '/carrinho',
                                    },
                                    {
                                        title: 'Endereço',
                                        href: '/carrinho/address'
                                    },
                                    {
                                        title: 'Entrega',
                                        href: '/carrinho/delivery'
                                    },
                                    {
                                        title: 'Pagamento',
                                        href: '/carrinho/payment'
                                    },
                                    {
                                        title: 'Resumo'
                                    },
                                    ]} activeStep={0} />
                            </div>
                        </div>
                         <h2 className="checkout-title " >Carrinho <ShoppingBag/> </h2>
                        
                        <div className='row'>

                        <div className='col-lg-9 col-sm-12' >
                            <table className={style.customTable}>
                                <thead>
                                    <tr>
                                        <th>Foto</th>
                                        <th>Produto</th>
                                        <th>Preço</th>
                                        <th>Quantidade</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {cartlist.length > 0 ?
                                        cartlist.map((item, index) =>
                                            <tr key={index} style={{ position: 'relative' }}>
                                                <td className="product-col">
                                                    <div className="product">

                                                        <Link style={{ width: 100, height: 100 }} to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                            <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={STORAGE_URL + item.product.header_info.header_images[0].image} alt="Product" />
                                                        </Link>
                                                    </div>

                                                </td>
                                                <td className="product-col">
                                                    <div className="product">


                                                        <p className="product-title" style={{marginBottom:0}}>
                                                            <Link to={`/produto/${item.product.name.toLowerCase().replaceAll(" ", "-")}`}>{item.product.name}</Link>
                                                        </p>
                                                    </div>
                                                </td>

                                                <td className="price-col">
                                                    {item.product.header_info.discount && <CustomText
                                                        text={item.product.header_info.price}
                                                        bold
                                                        style={{
                                                            textDecoration: "line-through",
                                                            marginRight: 1
                                                        }}
                                                        color="#878787"
                                                        size="1xrem"
                                                    />}
                                                    {moneyMask(item.product.header_info.discount ?

                                                        discountCalculator(
                                                            item.product.header_info.price,
                                                            item.product.header_info.discount,
                                                            item.product.header_info.is_percentage_discount
                                                        ) :

                                                        item.product.header_info.price

                                                    )}
                                                </td>

                                                <td className="quantity-col">


                                                    <div hidden={this.state.loading == true} className="cart-product-quantity" >


                                                        <div style={{ display: 'flex' }}>

                                                            <div className="btn-qty-custom-left" onClick={(e) => {
                                                                if (parseInt(item.quantity) - 1 > 0 && this.state.loading_add[item.product_id] != true) {
                                                                    // this.salvar(item.id, parseInt(item.qty) - 1, 1);
                                                                    this.addToCart(item.product_id, parseInt(item.quantity) - 1)
                                                                }
                                                            }}>
                                                                -
                                                            </div>
                                                            <div className="input-qty-custom">
                                                                {item.quantity}
                                                            </div>
                                                            <div className="btn-qty-custom-right" onClick={(e) => {
                                                                if (parseInt(item.quantity) < parseInt(item.product.header_info.quantity) && parseInt(item.quantity) < parseInt(item.product.header_info.purchase_quantity_limit) && this.state.loading_add[item.product_id] != true) {
                                                                    this.addToCart(item.product_id, parseInt(item.quantity) + 1)
                                                                }
                                                            }}>
                                                                +
                                                            </div>
                                                        </div>

                                                    </div>

                                                </td>

                                                <td className="total-col">
                                                    {moneyMask((item.product.header_info.discount ?

                                                        discountCalculator(
                                                            item.product.header_info.price,
                                                            item.product.header_info.discount,
                                                            item.product.header_info.is_percentage_discount
                                                        ) :

                                                        undoMoneyMask(item.product.header_info.price)

                                                    )*parseInt(item.quantity))}
                                                </td>

                                                <td className="remove-col">
                                                    {this.state.loading_add[item.product_id] != true && <button className="btn-remove" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.addToCart(item.product_id,0)
                                                        // this.remove(item.id);

                                                    }}><XCircle size={20} /> </button>}
                                                    {this.state.loading_add[item.product_id] == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress /></div>}
                                                </td>

                                            </tr>
                                        ) :
                                        <tr>
                                            <th colSpan="4">
                                                <p className="pl-2 pt-1 pb-1"> Não há produtos no carrinho </p>
                                            </th>
                                        </tr>
                                    }

                                </tbody>
                            </table>


                        </div>
                        <aside className="col-lg-3 col-sm-12">
                            <div className="summary summary-cart">
                                <p className="summary-title">Valor Total</p>
                                <hr/>
                                <p className="">Subtotal: {moneyMask(this.calculateTotal())}</p>
                                <hr/>

                               

                                <Link style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0} to={`${process.env.PUBLIC_URL}/carrinho/address`}
                                    className="btn btn-outline-primary btn-order btn-block"

                                >
                                    <span className="btn-text">Continuar </span>
                                    <span className="btn-hover-text"><ArrowRight/> </span>
                                </Link>
                                {this.props.token == undefined && this.props.cartlist.length > 0 && <Link style={{textDecoration:'none'}} to={'/login?go=cart'}> <button style={{ marginTop: '1.2rem',textDecoration:'none' }}
                                    onClick={() => {
                                        // this.props.showModal('login');
                                    }}
                                    className="btn btn-outline-primary btn-order btn-block"

                                >
                                    <span>Faça Login para Continuar</span>
                                </button></Link>}
                            </div>

                            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-outline-dark btn-block mt-3"><span>Continuar Comprando</span> <Bag/></Link>
                        </aside>
                        </div>

                    </Container>

                </div>
            </div>


        )
    }
}

export const mapStateToProps = (state) => {
    return ({
        cartlist: state.AppReducer.carts,
        shipping: state.AppReducer.shipping,
        token: state.AppReducer.token,

    })
}

export default connect(mapStateToProps, { mudarDados })(Cart);