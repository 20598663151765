import React from "react";

const INITIAL_STATE = {
  token: null,
  user: {},
  collapsed: false,
  toggled: false,
  currentPage: 1,
  carts:[],
  address_select:{},
  address_charge:{},
  shipping:{},
  shippings:[],
  card:{}

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "dados":
      console.log(action.payload)
      return {
        ...state,
        ...action.payload,
      };

    case "logout":
      return {
        ...state,
        token: null,
        user: {},
      };
    case "user":
      return {
        ...state,
        user: action.payload,
      };
    case "login":
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    default:
      return { ...state };
  }
};
