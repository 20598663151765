import Navbar from "components/Navbar";
import LoginPage from "pages/Auth/Login";
import Cart from "pages/Cart/cart";
import CartAddress from "pages/Cart/address";
import CartDelivery from "pages/Cart/delivery";
import CartPayment from "pages/Cart/payment";
import CartResume from "pages/Cart/resume";



import HomePage from "pages/Home";
import PesquisarPage from "pages/Pesquisar";
import SobreNosPage from "pages/SobreNos";
import VerProdutoPage from "pages/VerProduto";
import { Route, Routes } from "react-router-dom";

const GuestRoutes = () => {
  return (
    <>
      <Navbar />

      <Routes>
        {/*  */}
        <Route path={"/*"} element={ <HomePage  />} />
        <Route path={"/pesquisar"} element={<PesquisarPage />} />
        <Route path={"/produto/:name"} element={<VerProdutoPage />} />
        <Route path={"/sobre-nos"} element={<SobreNosPage />} />
        <Route path={"/carrinho"} element={<Cart />} />
        <Route path={"/carrinho/address"} element={<CartAddress />} />
        <Route path={"/carrinho/delivery"} element={<CartDelivery  />} />
        <Route path={"/carrinho/payment"} element={<CartPayment  />} />
        <Route path={"/carrinho/resume"} element={<CartResume  />} />





        {/* auth */}
        <Route path={"/login"} element={<LoginPage />} />
      </Routes>

      {/* <Footer /> */}
    </>
  );
};

export default GuestRoutes;
