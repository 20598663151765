import { STORAGE_URL } from "./variables";
import image_not_found from "assets/static/image_low_quality_low.jpg";

export const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  // localStorage.removeItem("first_access");
  // dispatch({ type: "login", payload: { token: null, user: null } });
  // dispatch({ type: "cart_items", payload: null });
  window.location.href = "/";
};

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}

// check if string has base64
export const checkBase64 = (string) => {
  if (!string) return image_not_found;

  if (string.includes("data:image")) {
    return string;
  } else {
    return STORAGE_URL + string;
  }
};

export const discountCalculator = (price, discount, is_percentage_discount) => {
  price = undoMoneyMask(price);
  discount = undoMoneyMask(discount);

  if (is_percentage_discount) {
    return price - (price * discount) / 100;
  } else {
    return price - discount;
  }
};

// money mask
export const moneyMask = (value) => {
  if(value == undefined){
    return '-';
  }
  console.log(value)
  value = value.toString().replace(",", ".").replace('R$ ', "")
  value = parseFloat(value).toFixed(2)
  try {
    value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
  } catch (e) {
    try {
      value = value
        .toString()
        .replace(".", "")
        .replace(",", "")
        .replace(/\D/g, "");
    } catch (e) {
      return 0;
    }
  }

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );

  return "R$ " + result;
};

// undo money mask
export const undoMoneyMask = (value) => {
  try {
    value = value.toString().replace(".", "").replace(",", "").replace(/\D/g, "");
    value = value.substring(0,value.length-2)
  } catch (e) {
    try {
      value = value
        .toString()
        .replace(".", "")
        .replace(",", "")
        .replace(/\D/g, "");
    } catch (e) {
      return 0;
    }
  }

  return value;
};

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

export const maskCep = (text) => {
  let text2 = text.replace('-', '');


  let final_num = '';

  for (let i = 0; i < text2.length && i < 8; i++) {
      // if (Number.isInteger(text[i])) {
      if (!isNaN(text2[i])) {

          if (i == 5) {
              final_num += '-' + text2[i];
          }
          else {
              final_num += text2[i] + '';
          }
      }
      // }
  }
  return final_num;
  // this.setState({ cep: final_num });

}

export const maskCpfCnpj = (text) => {
  let text2 = text.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '');

  let final_num = '';
  if (text2.length <= 11) {
      for (let i = 0; i < text2.length && i < 11; i++) {
          // if (Number.isInteger(text[i])) {
          if (!isNaN(text2[i])) {

              if (i == 3) {
                  final_num += '.' + text2[i];
              }
              else if (i == 6) {
                  final_num += '.' + text2[i];
              }
              else if (i == 9) {
                  final_num += '-' + text2[i];
              }
              else {
                  final_num += text2[i] + '';
              }
          }
          // }
      }
  }
  else {
      // 35.590.099/0001-03
      for (let i = 0; i < text2.length && i < 14; i++) {
          // if (Number.isInteger(text[i])) {
          if (!isNaN(text2[i])) {

              if (i == 2) {
                  final_num += '.' + text2[i];
              }
              else if (i == 5) {
                  final_num += '.' + text2[i];
              }
              else if (i == 8) {
                  final_num += '/' + text2[i];
              }
              else if (i == 12) {
                  final_num += '-' + text2[i];
              }
              else {
                  final_num += text2[i] + '';
              }
          }
          // }
      }
  }

  return final_num;
  // this.setState({ cpf: final_num });
}

export const maskCpf = (text) => {
  let text2 = text.replace('.', '').replace('-', '').replace('.', '');

  let final_num = '';

  for (let i = 0; i < text2.length && i < 11; i++) {
      // if (Number.isInteger(text[i])) {
      if (!isNaN(text2[i])) {

          if (i == 3) {
              final_num += '.' + text2[i];
          }
          else if (i == 6) {
              final_num += '.' + text2[i];
          }
          else if (i == 9) {
              final_num += '-' + text2[i];
          }
          else {
              final_num += text2[i] + '';
          }
      }
      // }
  }
  return final_num;
  // this.setState({ cpf: final_num });
}

export const maskNumber = (text2) => {

  text2 = text2.replace(/,/g,'.');
  let final_num = '';
  for (let i = 0; i < text2.length; i++) {
      
      if ((!isNaN(text2[i]) && text2[i]!=' ') || (text2[i] == '.' && !final_num.includes('.'))) {
          final_num += text2[i] + '';
      }
  }
  return final_num;
}

export const maskValidadeCartao = (text) => {

  let final_num = '';
  let text2 = text.replace(/\//g, '');
  for (let i = 0; i < text2.length && i < 6; i++) {
      if (!isNaN(text2[i])) {

          if (i == 2) {
              final_num += '/' + text2[i];
          }
          else {
              final_num += text2[i] + '';
          }
      }
  }
  return final_num;
}