import { BrowserRouter } from "react-router-dom";
import RoutesContainer from "routes";
// import "./responsive.css";
import { createTheme, ThemeProvider, responsiveFontSizes, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './colors.css'
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { login, mudarDados } from "components/actions/AppActions";
import { uuidv4 } from "utilities/helpers";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

let theme = createTheme();
theme = responsiveFontSizes(theme);
// with material ui theme, its possible to make text components responsive automatically

const queryClient = new QueryClient();

function App() {
  const [loading,setLoading]=useState(true);
  
  const dispatch = useDispatch();

  const get_cart = async ()=>{
    const res = await get("cart/get?random_id="+localStorage.getItem('random_id') );
    if (res.httpCode === 200) {
      console.log(res)
      let data={}
      if(res.user==null){
        data.user={};
        data.token=null;

        localStorage.clear()
      }
      else{
        data.user={...res.user};
        data.token=localStorage.getItem("token");

      }
      dispatch(mudarDados({ carts:res.carts,...data}))

      setLoading(false)

    }
  }

  useEffect(()=>{
    // const token = localStorage.getItem("token");
    // const user = JSON.parse(localStorage.getItem("user") || "{}");
    // dispatch(login({token,user}));
    if(localStorage.getItem('random_id')==null){
      localStorage.setItem('random_id',uuidv4())
    }
    get_cart();
  },[]);

  

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{zIndex:100000}}
        />
        {loading ? <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}>
            <CircularProgress/> 
           </div>
           : <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <RoutesContainer />
          </BrowserRouter>
        </QueryClientProvider>}
      </div>
    </ThemeProvider>
  );
}

export default App;
