import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Navigate(props) {
    const navigate = useNavigate();

  useEffect(() => {
    if(props.navigate){
        navigate(props.navigate)
    }
  }, [props.navigate]);

  return (
   <></>
  );
}
