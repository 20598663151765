import { Route, Routes } from "react-router-dom";
import GuestRoutes from "./GuestRoutes";
import AdminRoutes from "./AdminRoutes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mudarDados } from "components/actions/AppActions";
import { get } from "utilities/requests";

const RoutesContainer = () => {

  return (
    <Routes>
      <Route path={"/*"} element={<GuestRoutes />} />
      <Route path={"/dashboard/*"} element={<AdminRoutes />} />
    </Routes>
  )
}

export default RoutesContainer;