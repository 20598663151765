import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import { Box, CircularProgress, Container, Select } from "@mui/material";

import Stepper from 'react-stepper-horizontal';
import { discountCalculator, maskCep, maskCpfCnpj, maskNumber, maskValidadeCartao, moneyMask, undoMoneyMask } from 'utilities/helpers';
import Breadcrumb from 'components/Header/breadcrumb';
import PageHeader from 'components/Header/page-header';
import { mudarDados } from 'components/actions/AppActions';
import BreadcrumbSection from 'pages/VerProduto/sections/Breadcrumb';
import style from "./style.module.css";
import { STORAGE_URL } from 'utilities/variables';
import { AddressBook, ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Bag, Cards, Truck, XCircle } from '@phosphor-icons/react';
import './style.css';
import CustomText from 'components/CustomText';
import { CreateToken, get, post } from 'utilities/requests';
import { toast } from 'react-toastify';
import Navigate from 'components/Navigate/navigate';
import moment from 'moment';
import amex from 'assets/images/cartoes/amex.png';
import elo from 'assets/images/cartoes/elo.png';
import hypercard from 'assets/images/cartoes/hypercard.png';
import mastercard from 'assets/images/cartoes/mastercard.png';
import visa from 'assets/images/cartoes/visa.png';
import jcb from 'assets/images/cartoes/jcb.png';
import aura from 'assets/images/cartoes/aura.png';
import diners_club from 'assets/images/cartoes/diners_club.png';
import discover from 'assets/images/cartoes/discover.png';

class Card {
    constructor() {
        var self = this; this.mask = { DEFAULT_CC: '0000 0000 0000 0000', DEFAULT_CVC: '000', }
        this.type = {
            VISA: { name: 'visa', detector: /^4/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 },
            MASTERCARD: { name: 'mastercard', detector: /^(5[1-5]|2(2(2[1-9]|[3-9])|[3-6]|7([0-1]|20)))/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 },
            AMEX: { name: 'amex', detector: /^3[47]/, cardLength: 15, cvcLength: 4, maskCC: '0000 000000 00000', maskCVC: '0000', order: 99 },
            DISCOVER: { name: 'discover', detector: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 2 },
            HIPERCARD: { name: 'hipercard', detector: /^(?:606282|384100|384140|384160)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 4 }, DINERS: { name: 'diners', detector: /^(300|301|302|303|304|305|36|38)/, cardLength: 14, cvcLength: 3, maskCC: '0000 000000 0000', maskCVC: self.mask.DEFAULT_CVC, order: 5 },
            JCB_15: { name: 'jcb_15', detector: /^(?:2131|1800)/, cardLength: 15, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 6 },
            JCB_16: { name: 'jcb_16', detector: /^(35)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 7 },
            ELO: { name: 'elo', detector: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67([0-6][0-9]|7[0-8])|9\d{3})|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|(1|2|3)[0-9]|8[5-9]|9[0-9])|5((3|9)[0-8]|4[1-9]|([0-2]|[5-8])\d)|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|6516(5[2-9]|[6-7]\d)|6550(2[1-9]|5[0-8]|(0|1|3|4)\d))\d*/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 1 },
            AURA: { name: 'aura', detector: /^((?!5066|5067|50900|504175|506699)50)/, cardLength: 19, cvcLength: 3, maskCC: '0000000000000000000', maskCVC: self.mask.DEFAULT_CVC, order: 3 }
        };
    }
}

function detect_card(text = '') {
    text = text.replace(/ /g, '');
    let card = new Card();
    let types = Object.values(card.type);
    let brand = '';
    let index = null;
    let masked_number = '';
    let card_length = 0;
    for (let i = 0; i < types.length; i++) {
        if (text.match(types[i].detector)) {
            brand = types[i].name;
            card_length = types[i].cardLength;
            index = i;
            break;
        }
    }
    if (index != null) {
        let index_mask = 0;

        for (let i = 0; i < text.length && i < types[index].cardLength; i++) {
            if (!isNaN(text[i])) {
                if (types[index].maskCC[index_mask] == ' ') {
                    masked_number += ' ';
                    index_mask++;
                }
                if (types[index].maskCC[index_mask] == '0') {
                    masked_number += text[i];
                }
                index_mask++;
            }

        }
    }
    else {
        masked_number = text;
    }
    return { brand, masked_number, index, card_length };
}

var regras_parcelas = [
    {
        min: 0.00,
        max: 39.99,
        parcela: 1
    },
    {
        min: 40.00,
        max: 59.99,
        parcela: 2
    },
    {
        min: 60.00,
        max: 79.99,
        parcela: 3
    },
    {
        min: 80.00,
        max: 99.99,
        parcela: 4
    },
    {
        min: 100.00,
        max: 299.99,
        parcela: 5
    },
    {
        min: 300.00,
        parcela: 6
    }
]


class CartPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // shipping: { delivery_method_id: '', final_shipping_cost: 0 },
            update: true,
            loading_frete: false,
            msgErro: '',
            loading_add: {},

            numero_cartao: '',
            cvv: '',
            validade: '',
            cpf_cnpj: '',
            nome: '',
            types: { brand: '', masked_number: '', card_length: '' },
            pagamento_hidden: true,
            installments: [],

            loading_save: false,
            address_select: {},
            endereco_hidden: true,
            precos_prazos: [],
            loading: true

        }
    }

    async componentDidMount() {
        console.log(this.props.shipping)
        if (this.props.token == null || this.props.user?.id == undefined || this.props.cartlist.length == 0) {
            this.setState({ navigate: '/carrinho' })
            return;
        }
        let address = this.props.address_select;
        let data = { card: this.props.user.cards.length > 0 ? this.props.user.cards[0] : {} };
        if (this.props.address_select?.id == undefined) {
            address = JSON.parse(localStorage.getItem('address_select') || '{}');
            if (address?.id != undefined && this.props.user.addresses.filter((item) => item.id == address.id).length > 0) {
                data.address_select = address
                data.address_charge = address
            }
            else {
                this.setState({ navigate: '/carrinho' })
                return;
            }

        }
        if (this.props.shipping?.delivery_method_id == undefined) {
            let shipping = JSON.parse(localStorage.getItem('shipping') || '{}');
            if (shipping?.delivery_method_id != undefined) {
                await this.get_shipping(address.id, shipping.delivery_method_id);
            }
            else {
                this.setState({ navigate: '/carrinho' })
                return;
            }

        }
        data.card.type='credit_card';
        this.props.mudarDados({ ...data })
        console.log('card', data)
        this.get(this.props.address_select?.id)
        // quantityInputs();

    }



    async store() {
        this.setState({ loading_save: true })
        const { id, httpCode } = await CreateToken({
            cvv: this.state.cvv, exp_month: this.state.validade.split('/')[0], exp_year: this.state.validade.split('/')[1], holder_name: this.state.nome, holder_document: this.state.cpf_cnpj.replace(/\D/g, ''), number: this.state.numero_cartao.replace(/ /g, ''), brand: this.state.types.brand.toLowerCase()
        })
        const res = await post("cards/create", JSON.stringify({ cvv: this.state.cvv, token: id, billing_address: this.state.address_select }));
        if (res.httpCode === 200) {
            console.log(res.carts)
            this.props.mudarDados({ user: res.user, card: {...res.user.cards[0],type:'credit_card'} })
            this.setState({
                loading_save: false, numero_cartao: '',
                cvv: '',
                validade: '',
                cpf_cnpj: '',
                nome: '',
                types: { brand: '', masked_number: '', card_length: '' },
                pagamento_hidden: true,
            })

            toast.success(res.message);

        } else {
            if (res.errors != null || res.error != null) {
                let errors = Object.values(res.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);

                //   setTimeout(()=>{
                //     this.get_processo();
                //   });
                toast.error(erro);

            }
            this.setState({ loading_save: false })
        }
        // setLoadingAdd(false);


    }

    async get_shipping(id, delivery_method_id) {
        const res = await post("cart/get_shipment_prices", JSON.stringify({
            produtos: this.props.cartlist.map((item) => {
                return {
                    id: item.product.id,
                    largura: item.product.largura,
                    altura: item.product.altura,
                    comprimento: item.product.comprimento,
                    peso: item.product.peso,
                    sale_price: item.product.header_info.discount ?

                        discountCalculator(
                            item.product.header_info.price,
                            item.product.header_info.discount,
                            item.product.header_info.is_percentage_discount
                        ) :

                        null,
                    price: undoMoneyMask(item.product.header_info.price),
                    qty: item.quantity
                }
            }),
            address_id: id,
        }));
        if (res.httpCode === 200 && res.errors == undefined) {
            let precos_prazos = [];
            for (let i = 0; i < res.precos_prazos.length; i++) {
                precos_prazos.push({
                    final_shipping_cost: res.precos_prazos[i].vltotal,
                    delivery_estimate_business_days: res.precos_prazos[i].prazo,
                    retirada: res.precos_prazos[i].retirada,
                    entrega_local: res.precos_prazos[i].entrega_local,
                    volumes: res.precos_prazos[i].packages ? res.precos_prazos[i].packages : null,
                    agency_id: res.precos_prazos[i].agency_id ? res.precos_prazos[i].agency_id : null,
                    delivery_method_id: res.precos_prazos[i].id ? res.precos_prazos[i].id : Math.random(),
                    description: res.precos_prazos[i].entrega_local == true ? 'Entrega local' : (res.precos_prazos[i].retirada == true
                        ? 'Retirada no estabelecimento' : res.precos_prazos[i].description),
                        volumes_package:res.volumes
                    
                })
            }
            if (precos_prazos.filter((item) => item.delivery_method_id == delivery_method_id).length > 0) {
                this.props.mudarDados({ shipping: precos_prazos.filter((item) => item.delivery_method_id == delivery_method_id)[0] })
            }
            else {
                this.setState({ navigate: '/carrinho' })

            }

        }
        else {
            this.timeout_shipping = setTimeout(() => {
                this.get_shipping(id)
            }, 5000)
        }
    }

    async get(id) {
        const res = await get("cart/get?random_id=" + localStorage.getItem('random_id'));
        if (res.httpCode === 200 && res.errors == undefined) {

            console.log('aquiii')
            let installments = []
            let frete = (this.props.shipping.delivery_method_id == undefined ? 0 : Number(this.props.shipping.final_shipping_cost));
            console.log(frete)
            console.log(this.calculateTotal())
            installments.push({ value: 1, label: '1x de ' + moneyMask(parseFloat(this.calculateTotal()) + frete) })
            let installment = 1

            for (let j = 2; j <= 12; j++) {
                console.log(frete,parseFloat(this.calculateTotal()))
                installments.push({ value: j, label: j + 'x de ' + moneyMask(((parseFloat(this.calculateTotal()) + frete) / j).toFixed(2)) });
            }
            console.log(installments)
            this.props.mudarDados({ user: res.user, carts: res.carts,installment })
            this.setState({ loading: false, installments })
        }
        else if (res.errors != undefined) {
            let errors = Object.values(res.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                else
                    erro += errors[i];
            }
            console.log(erro);

            //   setTimeout(()=>{
            //     this.get_processo();
            //   });
            toast.error(erro);

        }
        else {
            this.timeout_shipping = setTimeout(() => {
                this.get(id)
            }, 5000)
        }
    }



    calculateTotal() {
        let total = 0;
        this.props.cartlist.map((item) => {
            total += (item.product.header_info.discount ?

                discountCalculator(
                    item.product.header_info.price,
                    item.product.header_info.discount,
                    item.product.header_info.is_percentage_discount
                ) :

                undoMoneyMask(item.product.header_info.price)

            ) * parseInt(item.quantity)

        })

        return total.toFixed(2);

    }

    componentWillUnmount() {
        clearTimeout(this.timeout_shipping);
    }





    render() {
        const { cartlist, total, removeFromCart } = this.props;
        let endereco = this.props.address_select;
        let shipping = this.props.shipping;

        console.log(endereco);

        let logo = '';
        let brand = '';
        if (this.state.types.brand != '') {
            brand = this.state.types.brand.toUpperCase();
            console.log(brand);
            if (brand.includes('VISA')) {
                logo = visa;
            }
            else if (brand.includes('MASTER')) {
                logo = mastercard;
            }
            else if (brand.includes('HYPER')) {
                logo = hypercard;
            }
            else if (brand.includes('AME')) {
                logo = amex;
            }
            else if (brand.includes('ELO')) {
                logo = elo;
            }

            else if (brand.includes('DINERS')) {
                logo = diners_club;
            }
            else if (brand.includes('DISCOVER')) {
                logo = discover;
            }
            else if (brand.includes('AURA')) {
                logo = aura;
            }
            else if (brand.includes('JCB')) {
                logo = jcb;
            }

        }
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                }}
            >
                <Navigate navigate={this.state.navigate} />
                <div
                    style={{
                        // background: product.header_info?.background_color_2
                        //   ? `linear-gradient(180deg, ${product.header_info?.background_color_1} 0%, ${product.header_info?.background_color_2} 100%)`
                        //   : product.header_info?.background_color_1,
                        display: "flex",
                    }}
                >
                    <Container
                        maxWidth="lg"
                        sx={{
                            paddingBottom: "2rem",
                            marginTop: 2
                            // check if there is a second color
                        }}
                    >

                        <div >
                            <BreadcrumbSection
                                {...{
                                    header_info: {},
                                    name: 'Carrinho'
                                }}
                            />
                        </div>

                        <div className="container d-none d-sm-block">

                            <div className={'breadcrumb-nav'}>
                                <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                                    activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                                    steps={[{
                                        title: 'Carrinho',
                                        href: '/carrinho',
                                    },
                                    {
                                        title: 'Endereço',
                                        href: '/carrinho/address'
                                    },
                                    {
                                        title: 'Entrega',
                                        href: '/carrinho/delivery'
                                    },
                                    {
                                        title: 'Pagamento',
                                        href: '/carrinho/payment'
                                    },
                                    {
                                        title: 'Resumo'
                                    },
                                    ]} activeStep={3} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className="checkout-title" >Escolha uma opção de pagamento <Cards /> </h2>

                            </div>

                            <div className="col-lg-9">
                                {this.props.user.cards.length == 0 && this.state.loading == false && <div>
                                    <p>Nenhum cartão cadastrado</p>
                                </div>}
                                <div className="row">
                                    {/* <div className="col-12 col-lg-6" hidden={this.state.loading == true || this.state.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Enviar Para:</h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                    {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                                </h3>
                                            </div> */}

                                    <div className="col-12 col-lg-12" hidden={this.state.loading == true || this.props.user.cards.length == 0}>
                                        <div className="table-rep-plugin mb-4">

                                            <div className="table-responsive">

                                                <table className={style.customTable}>
                                                    <thead>
                                                        <tr>
                                                            <th></th>

                                                            <th>Cartão de Crédito</th>
                                                            <th>Nome do Cartão</th>

                                                            <th>Vencimento</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {this.props.user.cards.map((item, id) => (<tr key={id} hidden={this.props.user.cards.length == 0} >
                                                            <td>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20, marginBottom: 0, }}>
                                                                        <input type="radio"
                                                                            id={"shipping" + id}
                                                                            name="shipping"
                                                                            className="custom-control-input"
                                                                            // style={{
                                                                            //     width: '26px',
                                                                            //     height: '26px'
                                                                            // }}
                                                                            onChange={(e) => {
                                                                                console.log(item);
                                                                                item.type='credit_card'
                                                                                this.props.mudarDados({ card: item })
                                                                            }}
                                                                            checked={this.props.card.id == item.id}
                                                                        />
                                                                        <span className="custom-control-label" htmlFor={"shipping" + id}>
                                                                            <div className="d-flex align-items-center">

                                                                                <img src={visa} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                                                <img src={mastercard} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                                                <img src={amex} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                                                <img src={elo} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                                                <img src={hypercard} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />

                                                                                <img src={diners_club} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('DINERS') ? 'block' : 'none' }} />
                                                                                <img src={discover} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('DISCOVER') ? 'block' : 'none' }} />
                                                                                <img src={jcb} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('JCB') ? 'block' : 'none' }} />
                                                                                <img src={aura} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('AURA') ? 'block' : 'none' }} />

                                                                            </div>
                                                                        </span>

                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td style={{ padding: 10 }}>

                                                                <div className="align-items-center d-flex ">

                                                                    <div className="align-items-center" style={{ marginLeft: 0 }}>
                                                                        <p style={{ margin: 0, color: 'black' }}><b style={{ marginRight: 15, fontWeight: 500 }}>(Crédito)</b> {item.brand.toUpperCase()}</p>

                                                                        <p style={{ margin: 0, color: 'black' }}> •••• {item.last_four_digits}</p>

                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td style={{ padding: 10 }}>

                                                                <div className="d-flex align-items-center ">
                                                                    <div>
                                                                        <p style={{ margin: 0, color: 'black' }}>{item.holder_name}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ padding: 10 }}>

                                                                <div className="d-flex  align-items-center">
                                                                    <div>
                                                                        <p style={{ margin: 0, color: 'black' }}>{moment(item.exp_month + '/' + item.exp_year, 'MM/YYYY', true).format('MM/YYYY')}</p>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {this.props.installment != undefined && <h2 className="checkout-title" style={{ marginTop: 0 }}>Número de parcelas</h2>}

                                        {this.props.installment != undefined && <select className="form-control" value={this.props.installment} onChange={(e) => {
                                            console.log(e);
                                            this.props.mudarDados({ installment: e.target.value });
                                        }}>
                                            {this.state.installments.map((item,id)=>(
                                                <option key={id} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>}


                                    </div>
                                </div>

                                {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></div>}
                                <hr />
                                <div style={{ border: '1px solid lightgrey', padding: 15, borderRadius: 5 }}>

                                    <h2 onClick={() => this.setState({ pagamento_hidden: !this.state.pagamento_hidden })} className="checkout-title show_hide" style={{ display: 'flex', alignItems: 'center', fontSize: '1.4rem', marginTop: '1rem', justifyContent: 'space-between' }}>Adicione um Cartão {this.state.pagamento_hidden != false ? <ArrowDown /> : <ArrowUp />} </h2>

                                    <div hidden={this.state.pagamento_hidden}>
                                        <label className='label_form'>Número do Cartão <span style={{ color: 'red' }}>*</span></label>
                                        <div style={{
                                            color: '#721c24',
                                            backgroundColor: '#f8d7da',
                                            borderColor: '#f5c6cb'
                                        }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                        </div>
                                        <br />
                                        <div style={{ position: 'relative' }}>

                                            <input value={this.state.numero_cartao} onChange={(e) => {
                                                let text = e.target.value;
                                                // let types = creditCardType(text);
                                                let result = detect_card(text);
                                                console.log(result)
                                                // let credit_card = maskCartaoCredito(text, types);
                                                this.setState({ numero_cartao: result.masked_number, types: result });
                                            }} style={{
                                                paddingRight: 40,
                                            }} type="text" placeholder="Número do Cartão" className="form-control" required />
                                            {logo != '' && <div style={{ position: 'absolute', top: 0, right: 10 }}>
                                                <img src={logo} style={{ width: 30, height: 40, objectFit: "contain" }} />
                                            </div>}
                                            <div className="d-flex">
                                                <img src={visa} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('VISA') ? '' : 'grayscale(1)' }} />
                                                <img src={mastercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('MASTER') ? '' : 'grayscale(1)' }} />
                                                <img src={amex} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AME') ? '' : 'grayscale(1)' }} />
                                                <img src={elo} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('ELO') ? '' : 'grayscale(1)' }} />
                                                <img src={hypercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('HYPER') ? '' : 'grayscale(1)' }} />

                                                <img src={diners_club} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('DINERS') ? '' : 'grayscale(1)' }} />
                                                <img src={discover} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('DISCOVER') ? '' : 'grayscale(1)' }} />
                                                <img src={jcb} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('JCB') ? '' : 'grayscale(1)' }} />
                                                <img src={aura} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AURA') ? '' : 'grayscale(1)' }} />
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-6">

                                                <label className='label_form'>Validade (MM/YYYY) <span style={{ color: 'red' }}>*</span></label>
                                                <input style={{ marginBottom: '0.5rem' }} value={this.state.validade} onChange={(e) => this.setState({ validade: maskValidadeCartao(e.target.value) })} type="text" placeholder="Validade MM/YYYY" className="form-control" required />
                                                {this.state.validade != '' && (!moment(this.state.validade, "MM/YYYY", true).isValid() || moment(this.state.validade, "MM/YYYY", true).diff(moment()) <= 0) && <small className="form-text" style={{ color: 'red' }}>Validade Inválida</small>}

                                            </div>
                                            <div className="col-12 col-md-6">

                                                <label className='label_form'>CVV <span style={{ color: 'red' }}>*</span></label>
                                                <input value={this.state.cvv} onChange={(e) => this.setState({ cvv: maskNumber(e.target.value) })} type="text" placeholder="CVV" className="form-control" required />
                                            </div>
                                        </div>


                                        <label className='label_form'>Nome do Titular <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} type="text" placeholder="Nome do Titular do Cartão" required className="form-control" />

                                        <label className='label_form'>CPF/CNPJ <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.cpf_cnpj} onChange={(e) => this.setState({ cpf_cnpj: maskCpfCnpj(e.target.value) })} type="text" placeholder="CPF/CNPJ do Titular do Cartão" className="form-control" required />



                                        <div className='d-flex justify-content-end'>
                                            {this.state.loading_save == false && <button onClick={() => {
                                                // this.salvar();
                                                this.store();
                                            }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                                <span>Salvar cartão</span>
                                                <i className="far fa-save"></i>
                                            </button>}
                                            {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress /></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <aside className="col-lg-3 col-sm-12">
                                <div className="summary summary-cart">
                                    <p className="summary-title">Seu pedido</p>
                                    <table className="table table-summary">
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {cartlist.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ color: 'black' }}><Link to="#">{item.quantity}x {item.product.name}</Link></td>
                                                    <td style={{ color: 'black' }}>
                                                        {moneyMask((parseFloat((item.product.header_info.discount ?

                                                            discountCalculator(
                                                                item.product.header_info.price,
                                                                item.product.header_info.discount,
                                                                item.product.header_info.is_percentage_discount
                                                            ) :

                                                            undoMoneyMask(item.product.header_info.price)

                                                        )) * parseInt(item.quantity)).toFixed(2))}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <hr />
                                    <p className=""><b>Subtotal:</b> {moneyMask(this.calculateTotal())}</p>
                                    {this.props.shipping && <p className=""><b>Entrega: </b> {moneyMask(this.props.shipping.final_shipping_cost)}</p>}
                                    <hr />
                                    {this.state.loading == false && <>

                                        {console.log(parseFloat(this.calculateTotal()), parseFloat(this.props.shipping.final_shipping_cost))}
                                        {this.props.shipping && <p className=""><b>Total: </b> {moneyMask((parseFloat(this.calculateTotal()) + parseFloat(this.props.shipping.final_shipping_cost)).toFixed(2))}</p>}


                                        <hr />



                                        <Link style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.props.user.addresses.length == 0 || this.state.loading_save == true || this.props.address_select.id == undefined || this.props.shipping?.delivery_method_id == undefined || this.props.card?.id==undefined} to={`${process.env.PUBLIC_URL}/carrinho/resume`}
                                            className="btn btn-outline-primary btn-order btn-block"
                                            onClick={(e) => {
                                                localStorage.setItem('card', JSON.stringify(this.props.card))
                                                localStorage.setItem('installment', this.props.installment)

                                            }}
                                        >
                                            <span className="btn-text">Continuar </span>
                                            <span className="btn-hover-text"><ArrowRight /> </span>
                                        </Link>
                                    </>}

                                </div>

                                <Link to={`${process.env.PUBLIC_URL}/carrinho/delivery`} className="btn btn-outline-dark btn-block mt-3"><span>Voltar</span> <ArrowLeft /></Link>
                            </aside>
                        </div>

                    </Container>

                </div>
            </div>


        )
    }
}

export const mapStateToProps = (state) => {
    return ({
        cartlist: state.AppReducer.carts,
        shipping: state.AppReducer.shipping,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        delivery: state.AppReducer.delivery,
        address_select: state.AppReducer.address_select,
        shippings: state.AppReducer.shippings,
        shipping: state.AppReducer.shipping,
        card: state.AppReducer.card,
        installment: state.AppReducer.installment,


    })
}

export default connect(mapStateToProps, { mudarDados })(CartPayment);