import { Route, Routes, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardOutlet from "pages/Admin";
import EditarPaginasHome from "pages/Admin/EditarPaginas/Home";
import EditarPaginasPesquisar from "pages/Admin/EditarPaginas/Pesquisar";
import EditarPaginasSobreNos from "pages/Admin/EditarPaginas/SobreNos";
import AdminProdutosPage from "pages/Admin/Produtos";
import Users from "pages/Admin/Users";

import CustomText from "components/CustomText";
import { Code } from "@phosphor-icons/react";
import AdminEditarProdutosPage from "pages/Admin/Produtos/EditarProduto";
import AdminUsuariosFormPage from "pages/Admin/Users/EditarUsuario";

const AdminRoutes = () => {
  const CheckIsAdmin = () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    if (token === null) {
      return <Navigate to="/login" />;
    } else {
      return <DashboardOutlet />;
    }

    // if (user?.role == "admin") {
    //   return <DashboardOutlet />;
    // } else {
    //   toast.error("Você não tem permissão para acessar esta página.");
    //   return <Navigate to="/login" />
    // }
  };

  return (
    <>
      <Routes>
        <Route path={"/"} element={<CheckIsAdmin />}>
          <Route
            path={"/"}
            element={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <CustomText text="Dashboard" size="3rem" bold />
                <CustomText
                  text="Em desenvolvimento"
                  size="1.4rem"
                  style={{
                    marginBottom: "2rem",
                  }}
                />
                <Code size={62} />
              </div>
            }
          />

          <Route path={"/produtos"} element={<AdminProdutosPage />} />
          <Route path={"/users"} element={<Users />} />

          <Route
            path={"/users/adicionar"}
            element={<AdminUsuariosFormPage />}
          />

<Route
            path={"/users/editar/:id"}
            element={
              <AdminUsuariosFormPage
                {...{
                  editing_mode: true,
                }}
              />
            }
          />

          <Route
            path={"/produtos/adicionar"}
            element={<AdminEditarProdutosPage />}
          />
          <Route
            path={"/produtos/editar/:id"}
            element={
              <AdminEditarProdutosPage
                {...{
                  editing_mode: true,
                }}
              />
            }
          />

          {/* TODO: customizar paginas */}
          <Route path={"/editar-home"} element={<EditarPaginasHome />} />
          <Route
            path={"/editar-pesquisar"}
            element={<EditarPaginasPesquisar />}
          />
          <Route
            path={"/editar-sobre-nos"}
            element={<EditarPaginasSobreNos />}
          />

          {/* <Route path={"/noticias"} element={<NoticiasPage />} />
          <Route path={"/meu-perfil"} element={<UserProfile />} />
          <Route path={"/solicitacoes"} element={<SolicitacoesPage />} />
          <Route path={"/mapa"} element={<MapaPage />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default AdminRoutes;
