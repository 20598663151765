import { IconButton } from "@mui/material";
import style from "./style.module.css";
import { Eye, PencilLine, Trash } from "@phosphor-icons/react";
import UserItem from "./UserItem";
import { useEffect, useState } from "react";
import windowReloadPage from "utilities/window_reload";
import { get } from "utilities/requests";
import CustomInput from "components/CustomInput";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UsersTable() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [search_debounce, setSearchDebounce] = useState("");

  const [users, setUsers] = useState([]);
  const me = useSelector(store=>store.AppReducer.user);
  console.log(me)
  const getUsers = async () => {
    const res = await get(`users/list?search=${search}`);
    setUsers(res.users);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    clearTimeout(search_debounce);

    setSearchDebounce(
      setTimeout(async () => {
        await getUsers();
      }, 500)
    );
  }, [search]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        <CustomInput
          placeholder="Pesquisar usuário"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            width: "100%",
            maxWidth: "30rem",
          }}
        />

        {/* <label
          htmlFor="upload_tag_button"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
            width: "fit-content",
          }}
          onClick={() => navigate("adicionar")}
        >
          Adicionar usuário
        </label> */}
      </div>
      <table className={style.customTable}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Tipo</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {users.map((user, index) => (
            <UserItem
              key={index}
              block_delete={me.id==user.id}
              {...{
                user,
                refetch: getUsers,
              }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
