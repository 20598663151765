import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import { Box, CircularProgress, Container } from "@mui/material";

import Stepper from 'react-stepper-horizontal';
import { discountCalculator, maskCep, maskCpf, moneyMask, undoMoneyMask } from 'utilities/helpers';
import Breadcrumb from 'components/Header/breadcrumb';
import PageHeader from 'components/Header/page-header';
import { mudarDados } from 'components/actions/AppActions';
import BreadcrumbSection from 'pages/VerProduto/sections/Breadcrumb';
import style from "./style.module.css";
import { STORAGE_URL } from 'utilities/variables';
import { AddressBook, ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Bag, Truck, XCircle } from '@phosphor-icons/react';
import './style.css';
import CustomText from 'components/CustomText';
import { get, post } from 'utilities/requests';
import { toast } from 'react-toastify';
import Navigate from 'components/Navigate/navigate';
import Modal from 'react-modal';

class CartAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // shipping: { delivery_method_id: '', final_shipping_cost: 0 },
            update: true,
            loading_frete: false,
            msgErro: '',
            loading_add: {},
            zip: '',
            address: '',
            number: '',
            neighborhood: '',
            city: '',
            state: '',
            latitude: -1,
            longitude: -1,
            complement: '',
            loading_save: false,
            endereco_hidden: true,
            modal_open:false,
            msgErroResposta:'',cpf:'',genero:'',
            loading_save_cpf:false

        }
    }

    componentDidMount() {
        if (this.props.user.addresses.length == 0) {
            this.setState({ endereco_hidden: false })
        }
        else {
            this.props.mudarDados({ address_select: this.props.user.addresses.filter((item) => item.default == true)[0], address_charge: this.props.user.addresses.filter((item) => item.default == true)[0] })
        }
        if (this.props.token == null || this.props.user?.id == undefined || this.props.cartlist.length == 0) {
            this.setState({ navigate: '/carrinho' })

        }
        // quantityInputs();

    }

    async get_cart() {
        const res = await get("cart/get?random_id=" + localStorage.getItem('random_id'));
        if (res.httpCode === 200) {
            console.log(res.carts)
            let data = {}
            if (res.user == null) {
                data.user = {};
                data.token = null;

                localStorage.clear()
            }
            else {
                data.user = res.user;
            }
            this.props.mudarDados({ carts: res.carts, ...data })


        }
    }

    buscar_endereco(cep) {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    address: resp.logradouro,
                    neighborhood: resp.bairro,
                    city: resp.localidade,
                    state: resp.uf,
                    number: ''

                }, () => {
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }

    calculateTotal() {
        let total = 0;
        this.props.cartlist.map((item) => {
            total += (item.product.header_info.discount ?

                discountCalculator(
                    item.product.header_info.price,
                    item.product.header_info.discount,
                    item.product.header_info.is_percentage_discount
                ) :

                undoMoneyMask(item.product.header_info.price)

            ) * parseInt(item.quantity)

        })

        return total;

    }

    async store() {
        this.setState({ loading_save: true })

        const res = await post("addresses/store", JSON.stringify({
            address: this.state.address,
            number: this.state.number,
            neighborhood: this.state.neighborhood,
            city: this.state.city,
            complement: this.state.complement,
            state: this.state.state,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            zip: this.state.zip,

        }));
        if (res.httpCode === 200) {
            console.log(res.carts)
            this.setState({
                loading_save: false, endereco_hidden: true, zip: '',
                address: '',
                number: '',
                neighborhood: '',
                city: '',
                state: '',
                latitude: -1,
                longitude: -1,
                complement: ''
            })
            this.props.mudarDados({ user: res.user,address_select: res.user.addresses.filter((item) => item.default == true)[0], address_charge: res.user.addresses.filter((item) => item.default == true)[0] })


        } else {
            if (res.errors != null || res.error != null) {
                let errors = Object.values(res.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);

                //   setTimeout(()=>{
                //     this.get_processo();
                //   });
                toast.error(erro);

            }
            this.setState({ loading_save: false })
        }
        // setLoadingAdd(false);


    }


    async store_data() {
        this.setState({ loading_save_cpf: true })

        const res = await post("users/update_minimal", JSON.stringify({
            cpf: this.state.cpf,
        }));
        if (res.httpCode === 200) {
            console.log(res.carts)
            this.props.mudarDados({ user: res.user })
            this.setState({
                loading_save_cpf: false,
                navigate:'/carrinho/delivery'
            })


            

        } else {
            if (res.errors != null || res.error != null) {
                let errors = Object.values(res.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                console.log(erro);

                //   setTimeout(()=>{
                //     this.get_processo();
                //   });
                toast.error(erro);

            }
            this.setState({ loading_save_cpf: false })
        }
        // setLoadingAdd(false);


    }




    render() {
        const { cartlist, total, removeFromCart } = this.props;
        console.log(this.props.user)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                }}
            >
                <Navigate navigate={this.state.navigate} />

                <div
                    style={{
                        // background: product.header_info?.background_color_2
                        //   ? `linear-gradient(180deg, ${product.header_info?.background_color_1} 0%, ${product.header_info?.background_color_2} 100%)`
                        //   : product.header_info?.background_color_1,
                        display: "flex",
                    }}
                >
                    <Container
                        maxWidth="lg"
                        sx={{
                            paddingBottom: "2rem",
                            marginTop: 2
                            // check if there is a second color
                        }}
                    >

                        <div >
                            <BreadcrumbSection
                                {...{
                                    header_info: {},
                                    name: 'Carrinho'
                                }}
                            />
                        </div>

                        <div className="container d-none d-sm-block">

                            <div className={'breadcrumb-nav'}>
                                <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                                    activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                                    steps={[{
                                        title: 'Carrinho',
                                        href: '/carrinho',
                                    },
                                    {
                                        title: 'Endereço',
                                        href: '/carrinho/address'
                                    },
                                    {
                                        title: 'Entrega',
                                        href: '/carrinho/delivery'
                                    },
                                    {
                                        title: 'Pagamento',
                                        href: '/carrinho/payment'
                                    },
                                    {
                                        title: 'Resumo'
                                    },
                                    ]} activeStep={1} />
                            </div>
                        </div>
                        <div className='row'>

                            <div className='col-lg-9 col-sm-12' >
                                <div hidden={this.state.loading == true || this.props.user.addresses.length == 0}>
                                    <h2 className="checkout-title" >Selecione um Endereço <AddressBook /> </h2>

                                    <div className="row">

                                        {this.props.user.addresses.map((item, id) => (
                                            <div className="col-12 col-sm-6 col-lg-4 mt-1" key={id}>
                                                <div onClick={() => {
                                                    this.props.mudarDados({ address_select: item, address_charge: item })
                                                }} className="card select_address" style={{
                                                    border: this.props.address_select.id == item.id ? '1px solid green' : '1px solid lightgrey',
                                                    padding: '5px',
                                                    borderRadius: 5,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    height: '100%',
                                                    maxWidth: 260
                                                }}>
                                                    <div className="card-body" style={{
                                                        padding: 0, display: 'flex',
                                                        justifyContent: 'center',
                                                    }}>

                                                        <p className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1rem', fontWeight: '400' }}>
                                                            <span style={{ marginBottom: '0.5rem' }}>{item.address.substring(0, 25)}{item.address.length > 25 ? '.' : ''} {item.number}</span>

                                                            {item.complement != null && item.complement != '' && '\n'}
                                                            {item.complement != null && item.complement != '' && <span style={{ marginBottom: '0.5rem' }}>{item.complement.substring(0, 25)}{item.complement.length > 25 ? '.' : ''}</span>}

                                                            {'\n'}
                                                            <span style={{ marginBottom: '0.5rem' }}>{item.neighborhood.substring(0, 25)}{item.neighborhood.length > 25 ? '.' : ''}{'\n'}</span>
                                                            <span style={{ marginBottom: '0.5rem' }}>{item.city.substring(0, 25)}{item.city.length > 25 ? '.' : ''} - {item.state}{'\n'}</span>
                                                            <span style={{ marginBottom: '0.5rem' }}>{item.zip}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                        {/* <div className="col-12 col-sm-6 col-lg-4" >
                                                <div onClick={() => {
                                                    this.setState({ select_option: 'add' });
                                                }} className="card" style={{
                                                    border: this.state.select_option == 'add' ? '1px solid green' : '1px solid lightgrey',
                                                    padding: '5px',
                                                    borderRadius: 5,
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}>
                                                    <div className="card-body" style={{
                                                        padding: 0, display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>

                                                        <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.3rem', fontWeight: '400' }}>
                                                            Adicionar um novo endereço
                                                            </h3>
                                                    </div>
                                                </div>

                                            </div> */}
                                    </div>

                                </div>
                                {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '2rem' }}><CircularProgress /></div>}
                                <hr />
                                <div style={{ border: '1px solid lightgrey', padding: 15, borderRadius: 5 }}>

                                    <h2 onClick={() => this.setState({ endereco_hidden: !this.state.endereco_hidden })} className="checkout-title show_hide" style={{ display: 'flex', alignItems: 'center', fontSize: '1.4rem', marginTop: '1rem', justifyContent: 'space-between' }}>Adicione um Novo Endereço {this.state.endereco_hidden != false ? <ArrowDown /> : <ArrowUp />} </h2>
                                    <div hidden={this.state.endereco_hidden}>

                                        <div style={{
                                            color: '#721c24',
                                            backgroundColor: '#f8d7da',
                                            borderColor: '#f5c6cb'
                                        }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                        </div>
                                        <label className='label_form'>Cep <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.zip} onChange={(e) => {
                                            this.setState({ zip: maskCep(e.target.value) });
                                            let text = e.target.value.replace('-', '');
                                            if (text.length == 8) {
                                                this.buscar_endereco(text);
                                            }
                                        }} type="text" placeholder="Cep" className="form-control" required />

                                        <label className='label_form'>Rua <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} type="text" placeholder="Rua" className="form-control" required />

                                        <label className='label_form'>Número <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.number} onChange={(e) => this.setState({ number: e.target.value })} type="text" placeholder="Número" className="form-control" required />

                                        <label className='label_form'>Complemento</label>
                                        <input value={this.state.complement} onChange={(e) => this.setState({ complement: e.target.value })} type="text" placeholder="Complemento" className="form-control" />

                                        <label className='label_form'>Bairro <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.neighborhood} onChange={(e) => this.setState({ neighborhood: e.target.value })} type="text" placeholder="Bairro" className="form-control" required />

                                        <label className='label_form'>Cidade <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} type="text" placeholder="Cidade" className="form-control" required />

                                        <label className='label_form'>Estado (Sigla) <span style={{ color: 'red' }}>*</span></label>
                                        <input value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} type="text" placeholder="Estado (Sigla)" className="form-control" required />



                                        <div className='d-flex justify-content-end'>

                                            {this.state.loading_save == false && <button onClick={() => {
                                                this.store();
                                            }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                                <span>Salvar Endereço</span>
                                                <i className="far fa-save"></i>
                                            </button>}
                                            {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress /></div>}
                                        </div>

                                    </div>

                                </div>



                            </div>
                            <aside className="col-lg-3 col-sm-12">
                                <div className="summary summary-cart">
                                    <p className="summary-title">Seu pedido</p>
                                    <table className="table table-summary">
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {cartlist.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ color: 'black' }}><Link to="#">{item.quantity}x {item.product.name}</Link></td>
                                                    <td style={{ color: 'black' }}>
                                                        {moneyMask((parseFloat((item.product.header_info.discount ?

                                                            discountCalculator(
                                                                item.product.header_info.price,
                                                                item.product.header_info.discount,
                                                                item.product.header_info.is_percentage_discount
                                                            ) :

                                                            undoMoneyMask(item.product.header_info.price)

                                                        )) * parseInt(item.quantity)).toFixed(2))}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <hr />
                                    <p className="">Subtotal: {moneyMask(this.calculateTotal())}</p>
                                    <hr />



                                    <button style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.props.user.addresses.length == 0 || this.state.loading_save == true || this.props.address_select.id == undefined} to={`${process.env.PUBLIC_URL}/carrinho/delivery`}
                                        className="btn btn-outline-primary btn-order btn-block"
                                        onClick={(e) => {
                                            this.setState({modal_open:true,cpf:this.props.user.cpf==null?'':this.props.user.cpf});
                                            localStorage.setItem('address_select', JSON.stringify(this.props.address_select))
                                        }}
                                    >
                                        <span className="btn-text">Continuar </span>
                                        <span className="btn-hover-text"><ArrowRight /> </span>
                                    </button>

                                </div>

                                <Link to={`${process.env.PUBLIC_URL}/carrinho`} className="btn btn-outline-dark btn-block mt-3"><span>Voltar</span> <ArrowLeft /></Link>
                            </aside>
                        </div>

                    </Container>

                    <Modal
                        isOpen={this.state.modal_open}
                        onRequestClose={() => this.setState({ modal_open: false })}
                        contentLabel="Example Modal"
                        style={customStyles}
                    >
                        <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                            this.setState({ modal_open: false });
                        }}>
                            <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                        </div>
                        <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                            <h4 style={{ textAlign: 'center' }}>Dados Pessoais</h4>
                        </div>

                        <div style={{ marginTop: 25 }}>
                            <p style={{ color: 'red' }}>{this.state.msgErroResposta}</p>
                            <div className="row">
                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
                                    <h5 style={{ fontSize: '1.2rem' }}>Preencha seu CPF <span style={{color:'red'}}>*</span></h5>

                                </div>

                            </div>

                            <input value={this.state.cpf} onChange={(e) => this.setState({ cpf: maskCpf(e.target.value) })} type="text" placeholder="CPF" className="form-control" required />

                            {/* <div className="row">
                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
                                    <h5 style={{ fontSize: '1.5rem' }}>Gênero</h5>

                                </div>

                            </div>

                            <select name="genero" id="genero" className="form-control" value={this.state.genero} onChange={(e) => {
                                this.setState({ genero: e.target.value });
                            }}>
                                <option value="">Selecione um Gênero</option>
                                <option value={'M'}>Masculino</option>
                                <option value={'F'}>Feminino</option>
                            </select> */}

                            {/* <input value={this.state.genero} onChange={(e) => this.setState({ genero: maskCpf(e.target.value) })} type="text" placeholder="CPF" className="form-control" required /> */}
                            {console.log(this.state.loading_save_cpf)}
                            <div className='d-flex justify-content-end'>

                            {this.state.loading_save_cpf == false && <button onClick={() => {
                                this.store_data();
                            }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                <span>Salvar</span>
                                <i className="far fa-save"></i>
                            </button>}
                            {this.state.loading_save_cpf == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress/></div>}
                            </div>

                        </div>

                    </Modal>

                </div>
            </div>


        )
    }
}

export const mapStateToProps = (state) => {
    return ({
        cartlist: state.AppReducer.carts,
        shipping: state.AppReducer.shipping,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        address_select: state.AppReducer.address_select,
    })
}
const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)',
        height: 270,
        top: '25%',
        right: 40,
        left: 40
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',

        // overflowY: 'scroll'
    }
};


export default connect(mapStateToProps, { mudarDados })(CartAddress);